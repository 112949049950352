let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARC rokasgrāmata`;

l.Header.Main.SetupDMARC        = `DMARC IESTATĪŠANA`;
l.Header.Main.HowItWorks        = `KĀ TAS DARBOJAS`;
l.Header.Main.AboutDMARC        = `PAR DMARC`;
l.Header.Main.Resources         = `AVOTI`;
l.Header.Main.Contact           = `KONTAKTI`;

l.Footer.Main.SetupDMARC        = `DMARC iestatīšana`;
l.Footer.Main.HowItWorks        = `Kā tas darbojas`;
l.Footer.Main.AboutDMARC        = `Par DMARC`;
l.Footer.Main.Resources         = `Avoti`;
l.Footer.Main.Contact           = `Kontakti`;
l.Footer.Main.Copyright         = `Autortiesības ¬¨¬© 2025 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Privātuma politika`;
l.Footer.Main.Sitemap           = `Vietnes karte`;

l.Home.Main.EnterDomain         = `Ievadiet savu domēnu,`;
l.Home.Main.ToStart             = `lai sāktu DMARC uzstādīšanu`;
l.Home.Main.TagLine             = `Dari kaut ko. Izmēri to.`;
l.Home.Main.Welcome             = `Laipni lūdzam DMARC iestatīšanas rokasgrāmatā. Šīs iestatīšanas rokasgrāmatas mērķis ir vadīt jūsu organizāciju, izstrādājot DMARC politiku, kā arī politikas attiecībā uz Sūtītāju politikas pamatprogrammu (Sender Policy Framework (SPF)) un Domēnu atslēgu identificētu pastu (DomainKeys Identified Mail (DKIM)). Ieviešot visas trīs politikas, jūsu organizācijai būs spēcīgāks e-pasta autentifikācijas mehānisms, kas palīdzēs aizsargāt zīmolu.`;
l.Home.Main.FirstStep           = `Pirmais solis ir apstiprināt, vai jūsu organizācija jau neizmanto nevienu no trim politikām.`;
l.Home.Main.InputWarning        = `Lūdzu, ievadiet domēnu no savas organizācijas e-pasta adreses, tieši tā, kā tas parādās pēc @ simbola e-pasta adresē.`;
l.Home.Main.InputExample        = `Piemēram, e-pasta adresei, info@globalcyberalliance.org, domēns, kas jāievada teksta lodziņā ir globalcyberalliance.org`;
l.Home.Main.InputDomain         = `jusudomens.com`;
l.Home.Main.Required            = `Vietnes adrese ir nepieciešama`;
l.Home.Main.valid               = `Vietnes adrese nav derīga`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google Apps un Office 365 lietotājiem:`;
l.Home.Main.UserWarningText1    = `Lūdzu, sekot informācijai, kas sniegta resursu lapā`;
l.Home.Main.UserWarningLink     = `Mākoņa pakalpojuma sniedzējiem,`;
l.Home.Main.UserWarningText2    = `lai ieviestu SPF un DKIM. Kad esat pabeidzis, izmantojiet šo rokasgrāmatu, lai turpinātu ieviest DMARC politiku.`;
l.Home.Main.Submit              = `Iesniegt`;

l.ToolSelect.Main.Title         = `Rezultāti`;
l.ToolSelect.Main.ResultStatus  = `Zemāk redzamā tabula parāda, kuri protokoli ir ieviesti <i class='fas fa-check-circle' style='color: green'></i> vai nav ieviesti <i class='fas fa-times-circle' style='color: red'></i>.`;
l.ToolSelect.Main.Protocol      = `Lūdzu, izvēlieties vienu no protokoliem, lai sāktu iestatīšanas pamācību.`;
l.ToolSelect.Main.ShareButton   = `Ja vēlaties kopīgot rezultātus, vienkārši noklikšķiniet uz pogas Kopīgot, saite tiks iekopēta starpliktuvē.`;
l.ToolSelect.Main.Selecting     = `Protokola izvēle parādīs arī pašreizējo ierakstu, ja tas būs pieejams.`;
l.ToolSelect.Main.DKIMWarning   = `DKIM pārbaudes sadaļa joprojām tiek izstrādāta. Tomēr DKIM iestatīšanas pamācība joprojām ir pieejama, izvēloties DKIM rindu.`;
l.ToolSelect.Main.NoDKIM        = `Mēs nevarējām atrast ar jūsu domēnu saistīto DKIM ierakstu pēc pieejamā noklusējuma atlasītāju saraksta.`;
l.ToolSelect.Main.NoDKIM1       = `Ja jūs zināt atlasītāju, jūs varat skenēt DKIM ierakstu šeit:`;
l.ToolSelect.Main.NoDKIM2       = `Vai arī, ja norādīsiet e-pasta adresi, mēs nosūtīsim e-pastu kontam, uz kuru varat atbildēt, un mēs skenēsim e-pastu, lai saņemtu DKIM ierakstu.`;
l.ToolSelect.Main.NoDKIMError   = `E-pasts nav derīgs vai nepieder pie norādītā domēna.`;
l.ToolSelect.Main.SelectorMsg   = `Tiek skenēts atlasītājs!`;
l.ToolSelect.Main.SelectorError = `Atlasītājs netika atrasts.`;
l.ToolSelect.Main.EmailMsg      = `E-pasta ziņojums nosūtīts! Lūdzu, atbildiet uz e-pastu. Kad atbilde tiks nosūtīta, šī lapa atjaunināsies un norādīs atlasītāja nosaukumu.`;
l.ToolSelect.Main.InputSelector = `Atlasītājs`;
l.ToolSelect.Main.InputEmail    = `lietotajs@piemers.com`;
l.ToolSelect.Main.Submit        = `Iesniegt`;
l.ToolSelect.Main.Select        = `Atlasīt`;
l.ToolSelect.Main.Next          = `Tālāk`;
l.ToolSelect.Main.Share         = `Kopīgot`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Jūs esat pabeidzis DMARC iestatīšanu`;
l.Complete.Main.check           = `Pārbaudīt citu SPF, DKIM vai DMARC domēnu?`;
l.Complete.Main.InputDomain     = `jusudomens.com`;
l.Complete.Main.Required        = `Vietnes adrese ir nepieciešama`;
l.Complete.Main.valid           = `Vietnes adrese nav derīga`;
l.Complete.Main.Next            = `Tālāk`;
l.Complete.Main.More            = `Vairāk par DMARC`;

l.SPF.Home.Title                = `Kas ir SPF?`;
l.SPF.Home.SPF                  = `SPF nozīmē Sender Policy Framework (Sūtītāju politikas pamatprogramma). Šī politika ir atbildīga par sūtītāja adreses aizsargāšanu no surogātpasta saņemšanas, nosakot, kuri pasta serveri ir pilnvaroti sūtīt vēstules organizācijas vārdā, Äö√Ñ√¥s domēnu. Ja pasta serveris nav definēts SPF, ziņojums tiek noraidīts vai atcelts.`;
l.SPF.Home.Start                = `Lūdzu, noklikšķiniet uz pogas "Tālāk", lai sāktu SPF politikas izveides procesu.`;
l.SPF.Home.Next                 = `Tālāk `;

l.SPF.Authorization.Title       = `Vai zemāk uzskaitītie serveri ir pilnvaroti nosūtīt e-pasta ziņojumu organizācijas domēnā?`;
l.SPF.Authorization.First       = `Pirmā politikas daļa ir domēna ienākošo pasta serveru (MXes) definēšana, kas ir pilnvaroti arī sūtīt e-pastus šajā domēnā.`;
l.SPF.Authorization.Current     = `Zemāk esošajā sarakstā ir iekļauti pašreizējie DNS ieraksti pasta serveriem, kas saistīti ar jūsu organizāciju.`;
l.SPF.Authorization.Recommended = `Ir ļoti ieteicams atlasīt Jā, jo iepriekš minētajam sarakstam vajadzētu būt jūsu domēna pilnvarotiem serveriem. To veicot, SPF politikai tiks pievienota birka mx.`;
l.SPF.Authorization.Yes         = `Jā ( ieteicams)`;
l.SPF.Authorization.No          = `Nē`;
l.SPF.Authorization.Missing     = `Ja sarakstā trūkst sistēmas vai tie nav standarta pasta serveri, tad iekļaujiet šīs sistēmas pie nākamās iespējas.`;
l.SPF.Authorization.Next        = `Tālāk`;

l.SPF.Servers.Title             = `Vai domēnā ir kādi papildu serveri, kas var piegādāt vai pārraidīt pastu šim domēnam?`;
l.SPF.Servers.Possible          = `Pastāv iespēja, ka ne visi organizācijas pasta serveri ir definēti organizācijas, Äö√Ñ√¥s DNS ierakstos. Tie varētu būt testa serveri vai lietojumprogrammas ar iebūvētām pasta sistēmām. Ja tas tā ir, lūdzu, pievienojiet papildu servera domēnus vai IP adreses zemāk esošajās ailēs.`;
l.SPF.Servers.Others            = `To vajadzētu izmantot tikai tad, ja ir citas iekšējās sistēmas, izņemot pasta serverus, kurām ir atļauts sūtīt domēna pastu. "`;
l.SPF.Servers.Added             = `Ja sistēmas tiek pievienotas, tas papildinās SPF politiku ar Äö√Ñ√≤a‚Äö√Ñ√¥ birku.`;
l.SPF.Servers.InputField        = `Servera nosaukums vai IP`;
l.SPF.Servers.InputInvalid      = `Jābūt pilnībā apstiprinātam domēna nosaukumam vai IP adresei`;
l.SPF.Servers.Next              = `Tālāk`;

l.SPF.Domains.Title             = `Vai ir kādi ārējie domēni, kas var piegādāt vai pārraidīt pastu jūsu organizācijai?`;
l.SPF.Domains.Include           = `Šī sadaļa pievienos`;
l.SPF.Domains.Include2          = `iekļaut`;
l.SPF.Domains.Include3          = `birku SPF politikai.`;
l.SPF.Domains.Trusted           = `To vajadzētu izmantot tikai tad, ja ārējam domēnam tiek atļauts nosūtīt jūsu organizācijai e-pasta ziņojumu, Äö√Ñ√¥s domēnu. Piemēram, klientu attiecību pārvaldības sistēma, Mākoņa pasta pakalpojumu sniedzējs (Google, Office 365, Yahoo!) vai Mākoņa drošības pakalpojumu sniedzējs.`;
l.SPF.Domains.Note              = `Lūdzu, ņemiet vērā: ja lietojat Mākoņa pakalpojumu sniedzēju, jums ir jāsadarbojas ar to, lai noteiktu šim iestatījumam atbilstošu vērtību.`;
l.SPF.Domains.InputField        = `Domēna nosaukums vai IP`;
l.SPF.Domains.InputInvalid      = `Jābūt pilnībā apstiprinātam domēna nosaukumam vai IP adresei`;
l.SPF.Domains.Next              = `Tālāk`;

l.SPF.Strict.Title              = `Cik strikti serveriem būtu jāapstrādā e-pastus?`;
l.SPF.Strict.Receiving          = `Šī ir pēdējā SPF politikas sadaļa, kas nosaka, cik strikti  saņemšanas serveriem būtu jāapstrādā e-pastus.`;
l.SPF.Strict.SoftFail           = `Soft Fail (Ieteicams)`;
l.SPF.Strict.Fail               = `Nevēlams`;
l.SPF.Strict.Neutral            = `Neitrāls`;
l.SPF.Strict.AllowAll           = `Atļaut visus`;
l.SPF.Strict.Next               = `Tālāk `;
l.SPF.Strict.Options            = `Iespējas:`;
l.SPF.Strict.FailEx             = `Nevēlams vai`;
l.SPF.Strict.FailEx2            = `nozīmē, ka tikai domēna pasta serveriem (un tiem, kas ietverti "a" un "iekļaut" sadaļās) ir atļauts sūtīt domēna vēstules. Visi pārējie ir aizliegti.`;
l.SPF.Strict.SoftEx             = `Soft Fail vai`;
l.SPF.Strict.SoftEx2            = `nozīmē, ka tikai domēna pasta serveriem (un tiem, kas iekļauti "a" un "iekļaut" sadaļās) ir atļauts sūtīt vēstules domēnam, bet tas ir pārejas posmā. Visi pārējie ir aizliegti.`;
l.SPF.Strict.NeutralEx          = `Neitrāls vai`;
l.SPF.Strict.NeutralEx2         = `skaidri norāda, ka neko nevar pateikt par derīgumu.`;
l.SPF.Strict.AllowEx            = `Atļaut visus vai`;
l.SPF.Strict.AllowEx2           = `nozīmē, ka jebkurš serveris var sūtīt domēna pastu.`;
l.SPF.Strict.Warning1           = `Šo `;
l.SPF.Strict.Warning2           = `nekad`;
l.SPF.Strict.Warning3           = `nevajadzētu izmantot`;
l.SPF.Strict.Recommended        = `Tiek ieteikts izmantot Soft Fail`;
l.SPF.Strict.MarkMail           = `Tas atzīmēs pastu kā neatbilstošu, ja tas neatbilst noteiktajiem kritērijiem.`;

l.SPF.Results.Title             = `Zemāk ir SPF TXT ieraksts, kas jāizveido jūsu DNS serverī.`;
l.SPF.Results.Copy              = `Kopēt`;
l.SPF.Results.Copied            = `NOKOPĒTS!`;
l.SPF.Results.DMARCGuide        = `Pāriet uz DMARC pamācību`;
l.SPF.Results.DKIMGuide         = `Turpināt uz DMARC pamācību`;
l.SPF.Results.OwnDNS            = `Ja jums ir savs DNS serveris, tad, lūdzu, izveidojiet iepriekš minētos SPF politikas TXT ierakstus.`;
l.SPF.Results.ThirdParty        = `Ja jūs izmantojat trešās puses DNS pakalpojumu sniedzēju, lūdzu, izpildiet tā norādījumus, lai izveidotu TXT ierakstu.`;
l.SPF.Results.Reference         = `Lūdzu, skatīt`;
l.SPF.Results.ResourcesPage     = `Resursu lapu,`;
l.SPF.Results.Information       = `lai iegūtu informāciju par to, kā izveidot TXT ierakstus.`;
l.SPF.Results.NotUnderstood     = `Ja mēģinājāt tai sekot, bet veicamās darbības vai izmantotās terminoloģijas nebija saprotamas, ieteicams sazināties ar savu IT atbalsta organizāciju, domēnu reģistrētāju vai GCA informācijas centru`;
l.SPF.Results.Assistance        = `tālākai palīdzībai.`;
l.SPF.Results.Implemented       = `Kad SPF ieraksts ir ieviests, lūdzu, izmantojiet SPF validāciju / pārbaudītāju zemāk:`;
l.SPF.Results.YourDomain        = `jusudomens.com`;
l.SPF.Results.Check             = `Pārbaudīt`;
l.SPF.Results.SuccessDKIM       = `DOTIES UZ DKIM`;
l.SPF.Results.SuccessDMARC      = `DOTIES UZ DMARC`;
l.SPF.Results.NotQuite          = `Vēl nav īsti galā.`;
l.SPF.Results.Wisdom            = `Viss kārtībā! Gudrība ir katras rīcības galīgais mērķis. Lūdzu, brīvi izmantojiet mūsu resursus, kas pieejami augstāk, vai rakstiet mums un mēs varam jums palīdzēt. Esam šeit, lai jums palīdzētu.`;

l.DKIM.Home.Title               = `Kas ir DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM nozīmē DomainKeys Identified Mail (Domēnu atslēgu identificētu pastu). DKIM ir mehānisms, kas paredzēts, lai apstiprinātu domēna identitāti, kas ir saistīta ar pasta ziņojumu. Tas tiek paveikts, izmantojot autentifikāciju, kurā tiek izmantota asimetriskā kriptogrāfija. Būtībā DKIM autentificēs pasta ziņojumu, pievienojot ziņojuma galvenei ciparparakstu. Svarīgi atzīmēt, ka DKIM nefiltrē pasta ziņojumus. Tas ļaus SPAM filtriem noteikt nosūtāmā pasta ziņojuma autentiskumu.`;
l.DKIM.Home.ClickNext           = `Lūdzu, noklikšķiniet uz pogas Tālāk, lai sāktu DKIM politikas izveides procesu.`;
l.DKIM.Home.YourDomain          = `jusudomens.com`;
l.DKIM.Home.Next                = `Tālāk`;

l.DKIM.Selector.Title           = `Atlasītājs`;
l.DKIM.Selector.Name            = `Atlasītājs nosaka DKIM ieraksta nosaukumu. Atlasītāja nosaukums var būt jebkas, bet tālāk ir dažas iespējas, no kurām izvēlēties.`;
l.DKIM.Selector.Define          = `Tas definēs TXT ieraksta nosaukumu, kas tiks izveidots DKIM atslēgai. Tas parādīsies šādi:`;
l.DKIM.Selector.Example1        = `atlasītājs`;
l.DKIM.Selector.Example2        = `domēna nosaukums`;
l.DKIM.Selector.Next            = `Tālāk`;

l.DKIM.Key.Title                = `Publiska atslēga`;
l.DKIM.Key.Tag                  = `Tas pievienos "p" birku, kas ir iepriekš izveidotās publiskās atslēgas faila galvenā saikne.`;
l.DKIM.Key.Keys                 = `Novietojiet publiskās atslēgas šeit`;
l.DKIM.Key.Next                 = `Tālāk`;
l.DKIM.Key.Creating             = `DKIM atslēgu izveide:`;
l.DKIM.Key.TwoParts             = `DKIM ir divas daļas, privātā atslēga un publiskā atslēga. DKIM atslēgu izveide mainīsies atkarībā no tā, kurš sniedz jūsu organizācijai e-pasta pakalpojumus.`;
l.DKIM.Key.External             = `Ja izmantojat ārēju pakalpojumu gan e-pastam, gan DNS, tad pasta pakalpojumu sniedzējam būs jāsniedz jums publiskā atslēga. Tad šo publisko atslēgu publicē kā TXT ierakstu DNS. Lielākā daļa DNS pakalpojumu sniedzēju ierobežo TXT ieraksta lielumu līdz 255 rakstzīmēm, tāpēc jums, iespējams, būs jādarbojas ar pakalpojumu sniedzēju, lai palielinātu izmēru vai izveidotu TXT ierakstu. Privāto atslēgu glabā pasta pakalpojumu sniedzējs, un parasti tā netiek izsniegta organizācijai.`;
l.DKIM.Key.Internal             = `Ja organizācijai ir savs e-pasta serveris, tad DKIM atslēgas izveido IT personāls (vai tas, kurš ir atbildīgs par DKIM ieviešanu). Internetā ir pieejami vairāki DKIM atslēgu ģeneratori. Ja jūs izvēlaties izmantot vienu no šiem, vienkārši pārliecinieties, ka jums ir iespēja dzēst atslēgu pēc tam, kad tā tiek ģenerēta un nokopēta uz failiem jūsu pusē.`;
l.DKIM.Key.OpenDKIM             = `Lai izveidotu atslēgas bez trešās puses, atvērtā avota projekts, saukts opendkim,`;
l.DKIM.Key.OpenDKIM2            = `ir pieejams. Opendkim satur dažādus rīkus, kas palīdz veidot DKIM atslēgu.`;
l.DKIM.Key.OpenSSL              = `Vēl viena iespēja ir izmantot OpenSSL, lai ģenerētu DKIM atslēgas. To var izdarīt, izmantojot jaunāko OpenSSL versiju un ierakstot šādu komandu privātajai atslēgai:`;
l.DKIM.Key.OpenSSL2             = `Tas ģenerēs privātās atslēgas failu, ko sauc`;
l.DKIM.Key.OpenSSL3             = `Pēc tam ierakstiet šādu komandu, lai ģenerētu publisko atslēgu:`;
l.DKIM.Key.OpenSSL4             = `Šī komanda ģenerēs publisko atslēgu,`;
l.DKIM.Key.OpenSSL5             = `pamatojoties uz iepriekš izveidoto privāto atslēgu`;
l.DKIM.Key.OpenSSL6             = `Pārvietojiet DKIM privāto atslēgu vietā, kas norādīta DKIM instalācijā. Pārliecinieties, vai tā atrodas mapē ar ierobežotu piekļuvi.`;

l.DKIM.Results.Title            = `Zemāk ir DKIM TXT ieraksts, kas jāizveido jūsu DNS serverī.`;
l.DKIM.Results.Copy             = `Kopēt`;
l.DKIM.Results.Copied           = `NOKOPĒTS!`;
l.DKIM.Results.SPFGuide         = `Atgriezties uz SPF pamācību`;
l.DKIM.Results.DMARCGuide       = `Turpināt uz DMARC pamācību`;
l.DKIM.Results.OwnDNS           = `Ja jums ir savs DNS serveris, lūdzu, izveidojiet TXT ierakstus iepriekšminētajai DKIM politikai.`;
l.DKIM.Results.ThirdParty       = `Ja jūs izmantojat trešās puses DNS pakalpojumu sniedzēju, lūdzu, izpildiet tā norādījumus, lai izveidotu TXT ierakstu.`;
l.DKIM.Results.Reference        = `Lūdzu, skatīt`;
l.DKIM.Results.ResourcesPage    = `Resursu lapu,`;
l.DKIM.Results.Information      = `lai iegūtu informāciju par to, kā izveidot TXT ierakstus.`;
l.DKIM.Results.NotUnderstood    = `Ja mēģinājāt tai sekot, bet veicamās darbības vai izmantotās terminoloģijas nebija saprotamas, ieteicams sazināties ar savu IT atbalsta organizāciju, domēnu reģistrētāju vai GCA informācijas centru`;
l.DKIM.Results.Assistance       = `tālākai palīdzībai.`;
l.DKIM.Results.Note             = `Lūdzu, ņemiet vērā:`;
l.DKIM.Results.Support          = `DKIM atslēgas tagad var atbalstīt 2048 bitu atslēgas. Tomēr DNS TXT ieraksti nevar atbalstīt vērtības, kas pārsniedz 255 rakstzīmes. Jūs varat to apiet, vai nu:`;
l.DKIM.Results.Support2         = `Lietojot 1024 bitu atslēgu, vai`;
l.DKIM.Results.Support3         = `Izveidojot daudzrindu TXT ierakstu`;
l.DKIM.Results.Support4         = `Tālāk ir norādīts daudzrindu ieraksta piemērs (pēdiņas pievienotas, lai sadalītu virkni vairākās rindās):`;

l.DMARC.Home.Title              = `Kas ir DMARC?`;
l.DMARC.Home.Final              = `Tagad, kad SPF un DKIM politikas ir izveidotas un ieviestas, mēs varam doties uz pēdējo soli, lai izveidotu DMARC politiku.`;
l.DMARC.Home.Protocol           = `DMARC (Domēna ziņojumu autentifikācija, pārskatu sniegšana un atbilstība) ir e-pasta autentifikācijas protokols, kas ietver pārskatu sniegšanas funkciju, kas ļauj sūtītājiem un uztvērējiem uzlabot un uzraudzīt domēna aizsardzību no krāpnieciskiem e-pasta ziņojumiem. DMARC ieviešana novērsīs surogātpasta sūtītjumus, ar adresi e-pasta "No" sadaļā. Atkarībā no DMARC politikas iestatījumiem, visi nesankcionēta pasta servera izcelsmes e-pasta ziņojumi tiks vai nu karantēti, vai noraidīti. Visi surogātpasta un pikšķerēšanas ziņojumi, izmantojot organizācijas domēna vārdu, tiek karantēti vai izdzēsti, pirms tie sasniedz galamērķi (gala lietotāju). Izveidotos ziņojumus var izmantot izlūkdatiem vai arī tos var izmantot tiesībaizsardzības nolūkos, ja darbība ir krimināli sodāma.`;
l.DMARC.Home.Stronger           = `DMARC apvienos SPF un DKIM, lai jūsu organizācijas domēnam nodrošinātu spēcīgāku e-pasta autentifikāciju.`;
l.DMARC.Home.ClickNext          = `Lūdzu, noklikšķiniet uz pogas Tālāk, lai sāktu DMARC politikas izveides procesu.`;
l.DMARC.Home.YourDomain         = `jusudomens.com`;
l.DMARC.Home.Next               = `Tālāk`;

l.DMARC.Policy.Title            = `Kādam jābūt DMARC politikas līmenim?`;
l.DMARC.Policy.Levels           = `DMARC ir iespējams iestatīt trīs politikas līmeņus. Tie ir:`;
l.DMARC.Policy.NoneEx           = `Neviens - ziņo par iespējamām aizdomīgām e-pasta ziņām, taču visi e-pasta ziņojumi tiek nosūtīti uz iesūtni`;
l.DMARC.Policy.QuarantineEx     = `Karantīna - ja nedarbojas SPF/DKIM politika, tad ziņa tiek nosūtīta mapei SPAM / Junk (mēstules)`;
l.DMARC.Policy.RejectEx         = `Noraidīt - ja nedarbojas SPF/DKIM politika, tad ziņa tiek izmesta un netiek piegādāta vispār`;
l.DMARC.Policy.None             = `Neviens (ieteicams)`;
l.DMARC.Policy.Quarantine       = `Karantīna`;
l.DMARC.Policy.Reject           = `Noraidīt`;
l.DMARC.Policy.Next             = `Tālāk`;
l.DMARC.Policy.Recommended1     = `Ir`;
l.DMARC.Policy.Recommended2     = `ļoti ieteicams`;
l.DMARC.Policy.Recommended3     = `sākt ar "Neviens", jo tas dos laiku pārraudzīt izveidotos pārskatus un noteikt, vai atbilstošs pasts tiks bloķēts pirms DMARC politikas maiņas uz karantīnu vai noraidīšanu.`;
l.DMARC.Policy.Tag              = `Tas pievienos`;
l.DMARC.Policy.Tag2             = `birku DMARC ierakstam.`;

l.DMARC.Aggregates.Title        = `Kur jāsūta kopsavilkuma ziņojumi?`;
l.DMARC.Aggregates.Opt          = `Tas nav obligāti, taču ir ļoti ieteicams un pievienos`;
l.DMARC.Aggregates.Opt2         = `rua birku politikai. Ir`;
l.DMARC.Aggregates.Opt3         = `ļoti ieteicams`;
l.DMARC.Aggregates.Opt4         = `ka to pievieno politikai, analīzes un uzraudzības mērķiem. Ziņojumus var nosūtīt uz vairākām adresēm.`;
l.DMARC.Aggregates.InputField   = `E-pasta adrese`;
l.DMARC.Aggregates.InputInvalid = `Lūdzu ievadiet derīgu e-pasta adresi`;
l.DMARC.Aggregates.Next         = `Tālāk`;
l.DMARC.Aggregates.Reports      = `Kopējie pārskati (XML formātā), tiks ģenerēti, iekļaujot informāciju par to, kuras pastkastes iztur/neiztur SPF un DKIM. Tas nodrošina jūsu organizācijas redzamību iespējamos autentifikācijas jautājumos un/vai mēstulēs.`;
l.DMARC.Aggregates.Contains     = `Pārskati satur sekojošo`;
l.DMARC.Aggregates.HowToRead    = `Kā lasīt savus pirmos DMARC ziņojumus`;
l.DMARC.Aggregates.By           = `(1. daļa), izstrādāja Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `ISP informācija`;
l.DMARC.Aggregates.Desc         = `Jūsu organizācijas DMARC ierakstu apraksts pēc kārtas, pa vienam`;
l.DMARC.Aggregates.Summary      = `Autentifikācijas rezultātu kopsavilkums`;
l.DMARC.Aggregates.Important    = `Ir svarīgi atzīmēt, ka šie ziņojumi netiks iegūti tikai no viena avota. Jūs katru dienu varētu saņemt 10 līdz 100 (iespējams, tūkstošiem) atskaites (radīto ziņojumu skaits ir atkarīgs no nosūtīto e-pasta ziņojumu daudzuma).`;
l.DMARC.Aggregates.Difficult    = `Tas apgrūtinās ikviena pārskata manuālu pārskatīšanu, jo īpaši tādēļ, ka tie ir XML formātā, un daži ziņojumi var būt gari.`;
l.DMARC.Aggregates.Readable     = `Šos pārskatus var padarīt cilvēkiem viegli lasāmus, izstrādājot XML pārveidotāju vai sadarbojoties ar tirgotāju. Lielākā daļa tirgotāju nodrošinās aizmugursistēmas repozitoriju, kas padarīs pārskatus cilvēkiem viegli lasāmus, kā arī nodrošinās dažādus analīzes un vadīšanas līmeņus.`;
l.DMARC.Aggregates.Vendors      = `DMARC tirgotāji (GCA partneri):`;

l.DMARC.Forensics.Title         = `Kur būtu jānosūta kriminālistikas ziņojumi?`;
l.DMARC.Forensics.Opt           = `Tas nav obligāts un pievienos`;
l.DMARC.Forensics.Opt2          = `ruf birku politikai. Šie XML pārskati tiks ģenerēti tikai tad, ja saņēmēja puse ģenerēs šāda veida pārskatus. Pastāv iespēja, ka jūs, iespējams, nesaņemsiet kriminālistikas pārskatus. Tas ir saistīts ar privātuma un datu kopīgas lietošanas noteikumiem valstīs.`;
l.DMARC.Forensics.InputField    = `E-pasta adrese`;
l.DMARC.Forensics.InputInvalid  = `Lūdzu ievadiet derīgu e-pasta adresi`;
l.DMARC.Forensics.Next          = `Tālāk`;

l.DMARC.Subdomains.Title        = `DMARC politika apakšdomēniem?`;
l.DMARC.Subdomains.Define       = `Tas definēs jūsu organizācijas domēna apakšdomēnu politikas līmeni. Ja šī birka`;
l.DMARC.Subdomains.Define2      = `nav pievienota, tad politika ievēros augstākā līmeņa domēna politiku, kas tika definēta pirmajā solī.`;
l.DMARC.Subdomains.None         = `Neviens`;
l.DMARC.Subdomains.Quarantine   = `Karantīna`;
l.DMARC.Subdomains.Reject       = `Noraidīt`;
l.DMARC.Subdomains.Next         = `Tālāk`;
l.DMARC.Subdomains.Policy       = `Domēna politika. Pastāv šādas iespējas:`;
l.DMARC.Subdomains.NoneEx       = `Neviens - neveic nekādas darbības, bet reģistrē DMARC pārskatā.`;
l.DMARC.Subdomains.QuarantineEx = `Karantīna - pasta ziņojums ir atzīmēts kā mēstule.`;
l.DMARC.Subdomains.RejectEx     = `Noraidīt - ziņa tiek dzēsta.`;
l.DMARC.Subdomains.Recommended  = `Ja jums ir vairāki apakšdomēni, ieteicams iestatīt "Neviens". Labāk ir izveidot atsevišķas DMARC politikas attiecībā uz katru apakšdomēnu, kamēr visi apakšdomēni neatbilst DMARC politikai. Taču, ja nav apakšdomēnu, tad ieteicams izmantot Noraidīt.`;

l.DMARC.Options.Title           = `(Pēc izvēles) Atlikušās iespējas nav nepieciešamas, bet vajadzības gadījumā tās var piemērot:`;
l.DMARC.Options.ReportGen       = `1. Kad būtu jāizveido kļūdas ziņojums`;
l.DMARC.Options.Default         = `Noklusējums`;
l.DMARC.Options.ReportPol       = `Definē kļūdas ziņojumu politiku.`;
l.DMARC.Options.DefaultsTo      = `Ja nav definēts, pēc noklusējuma ir`;
l.DMARC.Options.AllChecks       = `Izveidot pārskatu nosūtītājam MTA, ja kāda no pamatpārbaudēm neizdevās`;
l.DMARC.Options.AnyChecks       = `Izveidot pārskatu nosūtītājam MTA, ja kāda no pamatpārbaudēm neizdevās`;
l.DMARC.Options.OtherValues     = `Citas vērtības ir:`;
l.DMARC.Options.DKIMFails       = `Izveidot pārskatu, ja DKIM pārbaude neizdodas`;
l.DMARC.Options.SPFFails        = `Izveidot pārskatu, ja SPF pārbaude neizdodas`;
l.DMARC.Options.Relaxed         = `Atvieglots`;
l.DMARC.Options.Strict          = `Strikts`;
l.DMARC.Options.DKIMAlign       = `2. DKIM pielāgošanas režīms`;
l.DMARC.Options.DKIMAlignOpt    = `DKIM pielāgošanas režīms. Pastāv šādas iespējas:`;
l.DMARC.Options.DKIMRelaxed     = `(atvieglots) pēc noklusējuma, ja nav definēts. Pieļauj jebkuru apakšdomēnu, kas definēts DKIM galvenē.`;
l.DMARC.Options.DKIMStrict      = `(strikts), sūtītāja domēna nosaukumam precīzi jāatbilst domēnam DKIM galvenē.`;
l.DMARC.Options.SPFAlign        = `2. SPF pielāgošanas režīms`;
l.DMARC.Options.SPFAlignOpt     = `SPF pielāgošanas režīms. Pastāv šādas iespējas:`;
l.DMARC.Options.SPFRelaxed      = `(atvieglots) pēc noklusējuma, ja nav definēts. Pieļauj jebkuru apakšdomēnu.`;
l.DMARC.Options.SPFStrict       = `(strikts) - organizācijas domēna vārdam komandā PASTS NO (SMTP) un no: galvenē (pasta ziņā) ir precīzi jāsarīt`;
l.DMARC.Options.Percentage      = `4. Ziņojuma temats procentos pēc DMARC politikas?`;
l.DMARC.Options.Filter          = `% no ziņojumiem, kurus filtrē saskaņā ar DMARC politiku. Var būt jebkurš skaitlis no 1 līdz 100. Noklusējums ir 100, kas ir visi ziņojumi.`;
l.DMARC.Options.Formats         = `5. Radīto ziņojumu formāts`;
l.DMARC.Options.FormatsEx       = `Nosaka ģenerēto ziņojumu formātu. Formāta vērtības ir šādas:`;
l.DMARC.Options.RFC             = `noklusējuma vērtība, ja tā nav definēta. Formātu nosaka RFC 5965.`;
l.DMARC.Options.RFC2            = `Formātu nosaka RFC 5965.`;
l.DMARC.Options.ReportInt       = `6. Ziņošanas intervāls (sekundes)`;
l.DMARC.Options.SecondsDefault  = `sekundes (pēc noklusējuma)`;
l.DMARC.Options.DigitsOnly      = `Lūdzu, ievadiet tikai ciparus`;
l.DMARC.Options.ReportIntEx     = `Nosaka pārskata intervālus sekundēs. Ja nav definēts, noklusējuma vērtība ir 86400 sekundes vai 24 stundas. Lūdzu, ņemiet vērā, ka netiek garantēts, ka ziņojumi tiks nosūtīti, saņemot MTA. Ziņojumi tiek nosūtīti pēc labākās iespējas principa.`;
l.DMARC.Options.HToS            = `Stundu pārveidotājs sekundēs`;
l.DMARC.Options.Hours           = `24 stundas`;
l.DMARC.Options.To              = `uz`;
l.DMARC.Options.Seconds         = `86400 sekundēm`;
l.DMARC.Options.Finish          = `Beigas`;

l.DMARC.Results.Title           = `Zemāk ir DMARC TXT ieraksts, kas jāizveido jūsu DNS serverī.`;
l.DMARC.Results.Display         = `Parādīt DMARC TXT ieraksta rezultātu`;
l.DMARC.Results.Copy            = `Kopēt`;
l.DMARC.Results.Copied          = `NOKOPĒTS!`;
l.DMARC.Results.SPFGuide        = `Atgriezties uz SPF pamacību`;
l.DMARC.Results.DKIMGuide       = `Atgriezties uz DKIM pamacību`;
l.DMARC.Results.OwnDNS          = `Ja jums ir savs DNS serveris, izveidojiet TXT ierakstus tālāk redzamajai DMARC politikai.`;
l.DMARC.Results.ThirdParty      = `Ja jūs izmantojat trešās puses DNS pakalpojumu sniedzēju, lūdzu, izpildiet tā norādījumus, lai izveidotu TXT ierakstu. Vairumā gadījumu viss, kas būs nepieciešams, ir resursdatora nosaukums, _dmarc. <Domainname> un teksta vērtība, kas ir informācija, kas atrodama starp pēdiņām.`;
l.DMARC.Results.Reference       = `Lūdzu, skatīt`;
l.DMARC.Results.ResourcesPage   = `Resursu lapu,`;
l.DMARC.Results.Information     = `lai iegūtu informāciju par to, kā izveidot TXT ierakstus.`;
l.DMARC.Results.NotUnderstood   = `Ja mēģinājāt tai sekot, bet veicamās darbības vai izmantotās terminoloģijas nebija saprotamas, ieteicams sazināties ar savu IT atbalsta organizāciju, domēnu reģistrētāju vai GCA informācijas centru`;
l.DMARC.Results.Assistance      = `tālākai palīdzībai.`;
l.DMARC.Results.Complete        = `Pabeigt`;
l.DMARC.Results.Implemented     = `Kad DMARC ieraksts ir ieviests, lūdzu, izmantojiet DMARC validāciju/pārbaudītāju zemāk:`;
l.DMARC.Results.YourDomain      = `jusudomens.com`;
l.DMARC.Results.NotQuite        = `Vēl nav īsti galā.`;
l.DMARC.Results.Wisdom          = `Viss kārtībā! Gudrība ir katras rīcības galīgais mērķis. Lūdzu, brīvi izmantojiet mūsu resursus, kas pieejami augstāk, vai rakstiet mums un mēs varam jums palīdzēt. Esam šeit, lai jums palīdzētu.`;
l.DMARC.Results.Check           = `Pārbaudīt`;
l.DMARC.Results.Congratulations = `Apsveicam`;

export default l;
