let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `دليل مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;

l.Header.Main.SetupDMARC        = `مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;
l.Header.Main.HowItWorks        = `كيف يعمل`;
l.Header.Main.AboutDMARC        = `حول مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;
l.Header.Main.Resources         = `مصادر`;
l.Header.Main.Contact           = `تواصل`;

l.Footer.Main.SetupDMARC        = `إعداد نظام مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;
l.Footer.Main.HowItWorks        = `كيف يعمل`;
l.Footer.Main.AboutDMARC        = `نبذة عن نظام مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;
l.Footer.Main.Resources         = `مصادر`;
l.Footer.Main.Contact           = `تواصل`;
l.Footer.Main.Copyright         = `حقوق التأليف والنشر ¬¨¬ © 2025 تحالف سايبر العالمي`;
l.Footer.Main.PrivacyPolicy     = `سياسة الخصوصية`;
l.Footer.Main.Sitemap           = `خريطة الموقع`;

l.Home.Main.EnterDomain         = `أدخل النطاق الخاص بك`;
l.Home.Main.ToStart             = `لبدء إعداد نظام مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `مرحبا بك في دليل إعداد نظام مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC). الغرض من دليل الإعداد هذا هو توجيه مؤسستك من خلال عملية إنشاء سياسة  مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC)، بالإضافة إلى سياسات نظام التعرف على هوية المرسل (SPF) ومفاتيح النطاق لتشفير البريد ((DKIM)). من خلال تنفيذ جميع السياسات الثلاث، سيكون لدى مؤسستك آلية أقوى للتحقق من البريد الإلكتروني للمساعدة في حماية العلامة التجارية.`;
l.Home.Main.FirstStep           = `الخطوة الأولى هي التأكد ما إذا كانت مؤسستك تستخدم أو لا تستخدم أي من السياسات الثلاث.`;
l.Home.Main.InputWarning        = `يرجى إدخال النطاق من عنوان البريد الإلكتروني لمؤسستك، تماما كما يظهر بعد الرمز @ في خانة عنوان البريد الإلكتروني.`;
l.Home.Main.InputExample        = `على سبيل المثال، عنوان البريد الإلكتروني التالي، domainsecurityscanner@globalcyberalliance.org، النطاق الذي ينبغي إدخاله في مربع النص هو globalcyberalliance.org`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `عنوان الموقع الإلكتروني مطلوب`;
l.Home.Main.valid               = `عنوان الموقع الإلكتروني غير صحيح`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `تطبيقات جوجل ومستخدمو أوفيس 365:`;
l.Home.Main.UserWarningText1    = `يرجى اتباع المعلومات المتوفرة في صفحة المصادر (من أجل) عند  المواقع اللإلكترونية `;
l.Home.Main.UserWarningLink     = `موفرو خدمات كلاود`;
l.Home.Main.UserWarningText2    = `لتنفيذ سياسة نظام التعرف على هوية المرسِل (SPF) و مفاتيح النطاق لتشفير البريد (DKIM). يرجى منكم استخدام هذا الدليل بمجرد الانتهاء لمواصلة تنفيذ سياسة مصادقة الرسائل المستندة إلى النطاق والإبلاغ والتماثل (DMARC).`;
l.Home.Main.Submit              = `إرسال`;
l.Home.Main.MoreInfo            = `إذا كنت ترغب في الحصول على مزيد من المعلومات حول DMARC، فإننا ندعوك لحضور واحدة من دورات DMARC التعليمية الشهرية الخاصة بنا عبر الإنترنت. سوف يغطي هذا البرنامج التعليمي على الويب أساسيات DMARC وتقديم نظرة عامة موجزة عن دليل إعداد تحالف سايبر العالمي GCA DMARC.`;
l.Home.Main.Webinar             = `تنعقد جلسة البرنامج التعليمي GCA DMARC الشهري  عبر الموقع اللإلكتروني مرتين في يوم الأربعاء الثالث من كل شهر. يرجى التسجيل وفقاً للتاريخ والوقت المناسبين بشكل أفضل بالنسبة لك من خلال النقر على الرابط المناسب أدناه:`;
l.Home.Main.SelectDate          = `اختر هذا التاريخ`;

l.ToolSelect.Main.Title         = `نتائج`;
l.ToolSelect.Main.ResultStatus  = `الجدول أسفله يوضح البروتوكولات التي تم التحقق منها <i class='fas fa-check-circle' style='color: green'></i> أو لم يتم تنفيذها <i class='fas fa-times-circle' style='color: red'></i>.`;
l.ToolSelect.Main.Protocol      = `يرجى اختيار أحد البروتوكولات لبدء دليل الإعداد.`;
l.ToolSelect.Main.ShareButton   = `إذا كنت ترغب فقط في تبادل النتائج  فانقر على زر مشاركة، وسيتم نسخ رابط إلى الحافظة.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `لا يزال جزء التحقق من DKIM قيد التطوير. ومع ذلك، لا يزال دليل إعداد DKIM  متاحاً من خلال تحديد صف DKIM.`;
l.ToolSelect.Main.NoDKIM        = `لم نتمكن من العثور على سجل DKIM المقترن بنطاقك الذي يقدم قائمة المحددات الافتراضية.`;
l.ToolSelect.Main.NoDKIM1       = `إذا كنت تعرف المحدد فيمكنك البحث عن سجل DKIM هنا:`;
l.ToolSelect.Main.NoDKIM2       = `أو إذا قدمت عنوان بريدك الإلكتروني، فسنرسل رسالة إلى الحساب الذي يمكنك الرد عليه وسنقوم بفحص البريد الإلكتروني من أجل سجل DKIM.`;
l.ToolSelect.Main.NoDKIMError   = `البريد الإلكتروني غير صحيح أو لا ينتمي إلى النطاق الذي تم تقديمه.`;
l.ToolSelect.Main.SelectorMsg   = `بحث عن المحدد!`;
l.ToolSelect.Main.SelectorError = `لم يُعثَر على محدد.`;
l.ToolSelect.Main.EmailMsg      = `أُرسِلت الرسالة الإلكترونية! يرجى الرد على البريد الإلكتروني. سوف يتم تحديث هذه الصفحة وتقديم اسم المحدد بعد إرسال الرد.`;
l.ToolSelect.Main.InputSelector = `محدد`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `إرسال`;
l.ToolSelect.Main.Select        = `اختر`;
l.ToolSelect.Main.Next          = `التالي`;
l.ToolSelect.Main.Share         = `شارِك`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `لقد أكملت إعداد DMARC`;
l.Complete.Main.check           = `تحقق من نطاق آخر لإطار سياسة المرسِل SPF، أو DKIM، أو  DMARC؟`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `عنوان الموقع الإلكتروني مطلوب`;
l.Complete.Main.valid           = `عنوان الموقع الإلكتروني غير صحيح`;
l.Complete.Main.Next            = `التالي`;
l.Complete.Main.More            = `حول DMARC`;

l.SPF.Home.Title                = `ماذا يعني SPF؟`;
l.SPF.Home.SPF                  = `SPF يعني إطار سياسة التعرف على هوية المرسِل. هذه السياسة مسؤولة عن منع عنوان المرسِل من الغش من خلال تحديد خوادم البريد المسموح لهم لإرسال البريد بالنيابة عن المؤسسة والنطاق. إذا لم يتم تعريف خادم البريد الإلكتروني في نظام التعرف على هوية المرسِل (SPF)، فسيتم رفض أو رَد الرسالة.`;
l.SPF.Home.Start                = `يرجى النقر على الزر التالي لبدء عملية كيفية إنشاء سياسة نظام التعرف على هوية المرسِل (SPF).`;
l.SPF.Home.Next                 = `التالي`;

l.SPF.Authorization.Title       = `هل الخوادم أدناه مخولة لإرسال البريد الإلكتروني لنطاق المؤسسة؟`;
l.SPF.Authorization.First       = `الجزء الأول من السياسة هو تعريف خوادم البريد الإلكتروني الوارد (MXes) للنطاق المخول له أيضا بإرسال بريد إلكتروني لهذا النطاق.`;
l.SPF.Authorization.Current     = `تحتوي القائمة أدناه على سجلات نظام أسماء النطاقات (DNS) الحالية لخوادم البريد الإلكتروني المرتبطة بمؤسستك.`;
l.SPF.Authorization.Recommended = `يوصى بشدة اختيار نعم مثلما ينبغي أن تكون القائمة أعلاه هي الخوادم المعتمدة لنطاقك. من خلال القيام بذلك، العلامة mx سوف تضاف إلى سياسة نظام التعرف على هوية المرسِل (SPF).`;
l.SPF.Authorization.Yes         = `نعم (موصى به)`;
l.SPF.Authorization.No          = `لا`;
l.SPF.Authorization.Missing     = `إذا كانت هناك أنظمة مفقودة من القائمة أو ليست خوادم أساسية للبريد الإلكتلروني، فقم بتضمين تلك الأنظمة في الخيار التالي.`;
l.SPF.Authorization.Next        = `التالي`;

l.SPF.Servers.Title             = `هل هناك أي خوادم إضافية داخل النطاق قد تسلم أو ترحل البريد الإلكتروني لهذا النطاق؟`;
l.SPF.Servers.Possible          = `هناك احتمال عدم التعرف على كل خوادم بريد المؤسسة في سجلات نظام أسماء النطاقات (DNS) التابعة للمؤسسة. يمكن أن تكون هذه خوادم الاختبار أو تطبيقات مع أنظمة البريد المدمجة. إذا كان هذا هو الحال، فالرجاء إضافة نطاقات الخادم الإضافية أو عناوين بروتوكول الإنترنت (IP) في المربعات أدناه.`;
l.SPF.Servers.Others            = `يجب استخدام هذا فقط في حالة وجود أنظمة داخلية أخرى، بخلاف خوادم البريد، المخولة بإرسال البريد للنطاق. "`;
l.SPF.Servers.Added             = `إذا تم إضافة أنظمة، فإن هذا سيضيف علامة Äö√≤Ñ√≤a، Äö√Ñ¥ ¥ إلى سياسة نظام التعرف على هوية المرسِل (SPF).`;
l.SPF.Servers.InputField        = `اسم الخادم أو بروتوكول الإنترنت (IP)`;
l.SPF.Servers.InputInvalid      = `يجب أن يكون اسم النطاق أو عنوان بروتوكول الإنترنت (IP) مؤهل بالكامل`;
l.SPF.Servers.Next              = `التالي`;

l.SPF.Domains.Title             = `هل هناك أي نطاقات خارجية قد تسلم أو ترحل البريد الإلكتروني لمؤسستك؟`;
l.SPF.Domains.Include           = `سوف يضيف هذا القسم`;
l.SPF.Domains.Include2          = `يشمل`;
l.SPF.Domains.Include3          = `علامة سياسة نظام التعرف على هوية المرسِل (SPF).`;
l.SPF.Domains.Trusted           = `يجب استخدام هذا فقط إذا كان نطاق خارجي موثوق به لإرسال رسالة بريد إلكتروني لنطاق مؤسستك، Äö√Ñ¥ ¥ s. على سبيل المثال، نظام إدارة علاقات العملاء، مزود خدمة بريد كلاود (جوجل أو أوفيس 365 أو ياهو!) أو مزود أمان كلاود.`;
l.SPF.Domains.Note              = `يرجى ملاحظة ما يلي: إذا كنت تستخدم مزود خدمة كلاود، يجب أن تعمل معهم للحصول على القيمة المناسبة لهذا الإعداد.`;
l.SPF.Domains.InputField        = `اسم النطاق أو بروتوكول الإنترنت (IP)`;
l.SPF.Domains.InputInvalid      = `يجب أن يكون اسم النطاق أو عنوان بروتوكول الإنترنت (IP) مؤهل بالكامل`;
l.SPF.Domains.Next              = `التالي`;

l.SPF.Strict.Title              = `ما مدى صعوبة تعامل الخوادم مع رسائل البريد الإلكتروني؟`;
l.SPF.Strict.Receiving          = `هذا هو القسم الأخير من سياسة نظام التعرف على هوية المرسِل (SPF) الذي سيحدد مدى دقة خوادم الاستقبال عند معالجة رسائل البريد الإلكتروني.`;
l.SPF.Strict.SoftFail           = `إخفاق خفيف (موصى به)`;
l.SPF.Strict.Fail               = `إخفاق!`;
l.SPF.Strict.Neutral            = `محايد`;
l.SPF.Strict.AllowAll           = `اسمح للكل `;
l.SPF.Strict.Next               = `التالي`;
l.SPF.Strict.Options            = `خيارات:`;
l.SPF.Strict.FailEx             = `إخفاق أو `;
l.SPF.Strict.FailEx2            = `يعني فقط خوادم بريد النطاق (وتلك الموجودة في الأقسام المشمولة) يسمح لها بإرسال بريد للنطاق. محظور على الآخرين جميعاً.`;
l.SPF.Strict.SoftEx             = `إخفاق خفيف أو `;
l.SPF.Strict.SoftEx2            = `يعني فقط خوادم بريد النطاق (وتلك الموجودة في الأقسام المشمولة) يسمح لها بإرسال بريد للنطاق. محظور على الآخرين جميعاً.`;
l.SPF.Strict.NeutralEx          = `محايد أو `;
l.SPF.Strict.NeutralEx2         = `يعني صراحة أن لا شيء يمكن أن يقال عن الصلاحية.`;
l.SPF.Strict.AllowEx            = `اسمح للكل أو`;
l.SPF.Strict.AllowEx2           = `يعني أن أي مضيف يمكنه إرسال بريد للنطاق.`;
l.SPF.Strict.Warning1           = `هذا لا ينبغي `;
l.SPF.Strict.Warning2           = `أبداً`;
l.SPF.Strict.Warning3           = `أن يستخدم`;
l.SPF.Strict.Recommended        = `فمن المستحسن استخدام إخفاق خفيف`;
l.SPF.Strict.MarkMail           = `سوف يؤدي هذا إلى وضع علامة غير متوافق على البريد إذا لم تجتمع فيه المعايير المحددة.`;

l.SPF.Results.Title             = `في ما يلي نص سجل نصوص (TXT) سياسة نظام التعرف على هوية المرسِل SPF الذي يجب إنشاؤه على خادم نظام أسماء النطاقات DNS.`;
l.SPF.Results.Copy              = `نسخ`;
l.SPF.Results.Copied            = `تم النسخ!`;
l.SPF.Results.DMARCGuide        = `انتقل إلى دليل DMARC`;
l.SPF.Results.DKIMGuide         = `تابع إلى دليل DKIM`;
l.SPF.Results.OwnDNS            = `إذا كان لديك خادمك لنظام أسماء النطاقات DNS، فيرجى إنشاء سجلات النصوص TXT لسياسة نظام التعرف على هوية المرسِل (SPF) أعلاه.`;
l.SPF.Results.ThirdParty        = `إذا كنت تستخدم موفر نظام أسماء نطاقات تابع لطرف ثالث، فيرجى اتباع إرشادات إنشاء سجل النص (TXT).`;
l.SPF.Results.Reference         = `يرجى الرجوع إلى`;
l.SPF.Results.ResourcesPage     = `صفحة المصادر`;
l.SPF.Results.Information       = `للحصول على معلومات حول كيفية إنشاء سجلات النص (TXT).`;
l.SPF.Results.NotUnderstood     = `إذا كنت قد حاولت متابعة هذا ولكن لم تفهم الخطوات التي يجب اتخاذها أو المصطلحات المستخدمة، فمن المستحسن أن تتصل بمؤسسة دعم تكنولوجيا المعلومات، أو مسجل النطاق، أو معلومات تحالف السايبر العالمي (GCA)`;
l.SPF.Results.Assistance        = `لمزيد من المساعدة`;
l.SPF.Results.Implemented       = `بعد تنفيذ سجل نظام التعرف على هوية المرسِل (SPF)، يرجى استخدام أداة التحقق / فاحص نظام التعرف على هوية المرسِل (SPF) أدناه:`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `افحص`;
l.SPF.Results.SuccessDKIM       = `اذهب الى DKIM`;
l.SPF.Results.SuccessDMARC      = `اذهب الى DMARC`;
l.SPF.Results.NotQuite          = `ليس تماماً هناك بعد.`;
l.SPF.Results.Wisdom            = `هذا جيد! الحكمة هي الهدف النهائي من كل عمل. لا تتردد في الاستفادة من مصادرنا أعلاه، أو اترك لنا رسالة وبإمكاننا مساعدتك على التنفيذ. من أجل هذا نحن هنا.`;

l.DKIM.Home.Title               = `ما هو DKIM؟`;
l.DKIM.Home.Mechanism           = `DKIM يعني مفاتيح تحديد نطاق البريد الإلكتروني. DKIM هي آلية مصممة لغرض التحقق من هوية النطاق المقترنة برسالة البريد الإلكتروني. يتم ذلك باستخدام المصادقة التي تستخدم التشفير اللاتناظرية. في الأساس، سوف يتحقق DKIM من رسالة البريد الإلكتروني عن طريق إضافة توقيع رقمي إلى رأس صفحة الرسالة. من المهم ملاحظة أن مفاتيح النطاق لتشفير البريد DKIM لا تقوم بتصفية رسائل البريد الإلكتلروني. سوف يسمح باكتشاف البريد المزعج لتحديد صحة رسالة البريد التي يتم إرسالها.`;
l.DKIM.Home.ClickNext           = `يرجى النقر على الزر التالي لبدء عملية كيفية إنشاء سياسة نظام التعرف على هوية المرسِل (SPF).`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `التالي`;

l.DKIM.Selector.Title           = `محدد`;
l.DKIM.Selector.Name            = `سوف يحدد المحدد اسم سجل DKIM. يمكن أن يكون اسم المحدد أي شيء، ولكن توجد أدناه بعض الخيارات للاختيار من بينها.`;
l.DKIM.Selector.Define           = `سوف يحدد هذا اسم سجل النص (TXT) الذي سيتم إنشاؤه لمفتاح DKIM. سوف يظهر هذا على النحو التالي:`;
l.DKIM.Selector.Example1        = `محدد`;
l.DKIM.Selector.Example2        = `اسم النطاق`;
l.DKIM.Selector.Next            = `التالي`;

l.DKIM.Key.Title                = `مفتاح عام`;
l.DKIM.Key.Tag                  = `سيؤدي ذلك إلى إضافة العلامة "p"، وهي السلسلة الرئيسية في ملف المفتاح العام الذي تم إنشاؤه سابقاً.`;
l.DKIM.Key.Keys                 = `ضع المفاتيح العامة هنا`;
l.DKIM.Key.Next                 = `التالي`;
l.DKIM.Key.Creating             = `إنشاء مفاتيح DKIM:`;
l.DKIM.Key.TwoParts             = `لدى DKIM جزأين، المفتاح الخاص والمفتاح العمومي. سوف يختلف إنشاء مفاتيح DKIM وذلك وفقاً للجهة المُقَدِّمة لخدمات البريد الإلكتروني لمؤسستك.`;
l.DKIM.Key.External             = `إذا كنت تستخدم خدمة خارجية لكل من البريد و نظام أسماء النطاقات DNS، فينبغي على موفر خدمة البريد تزويدك بالمفتاح العمومي. ثم يتم نشر هذا المفتاح العمومي كسجل للنص (TXT) في نظام أسماء النطاقات. يقيد معظم موفري نظام أسماء النطاقات حجم سجل النصوص (TXT) إلى 255 حرفا، لذا قد يتعين عليك العمل مع موفر الخدمة لزيادة الحجم أو إنشاء سجل النص (TXT). يتم الاحتفاظ بالمفتاح الخاص من قبل موفر البريد، وعادة لا يتم توفيره للمؤسسة.`;
l.DKIM.Key.Internal             = `إذا كان لدى المؤسسة خادم بريد إلكتروني خاص بها، فيجب إنشاء مفاتيح DKIM من قبل موظفي تكنولوجيا المعلومات (أو المسؤول عن تنفيذ DKIM). هناك مولدات مفاتيح DKIM متعددة متوفرة على الإنترنت. إذا اخترت استخدام واحد من هذه، فقط تأكد من أن لديك الخيار لحذف المفتاح بعد أن يتم إنشاؤه ونسخه إلى الملفات الموجودة لديك.`;
l.DKIM.Key.OpenDKIM             = `لإنشاء مفاتيح بدون طرف ثالث، مشروع مفتوح المصدر يسمى opendkim`;
l.DKIM.Key.OpenDKIM2            = `متوفر يحتوي أوبندكيم على أدوات مختلفة للمساعدة في إنشاء مفتاح DKIM .`;
l.DKIM.Key.OpenSSL              = `خيار آخر هو استخدام OpenSSL لإنشاء مفاتيح DKIM. يمكن القيام بهذا من خلال استخدام أحدث إصدار من OpenSSL وكتابة الأمر التالي للمفتاح الخاص:`;
l.DKIM.Key.OpenSSL2             = `سيؤدي هذا إلى إنشاء ملف مفتاح خاص يسمى`;
l.DKIM.Key.OpenSSL3             = `بعد ذلك، اكتب الأمر التالي لإنشاء المفتاح العمومي:`;
l.DKIM.Key.OpenSSL4             = `سوف يقوم هذا الأمر بإنشاء المفتاح العمومي`;
l.DKIM.Key.OpenSSL5             = `استنادا إلى المفتاح الخاص الذي تم إنشاؤه مسبقا`;
l.DKIM.Key.OpenSSL6             = `حرك المفتاح الخاص DKIM في الموقع المحدد بواسطة تثبيت DKIM. تأكد من وجوده في مجلد مع وصول مقيد.`;

l.DKIM.Results.Title            = `في ما يلي نص سجل نصوص (TXT) سياسة نظام التعرف على هوية المرسِل SPF الذي يجب إنشاؤه على خادم نظام أسماء النطاقات DNS.`;
l.DKIM.Results.Copy             = `نسخ`;
l.DKIM.Results.Copied           = `تم النسخ!`;
l.DKIM.Results.SPFGuide         = `ارجع إلى دليل سياسة نظام التعرف على هوية المرسِل SPF`;
l.DKIM.Results.DMARCGuide       = `تابع إلى دليل DMARC`;
l.DKIM.Results.OwnDNS           = `إذا كان لديك خادمك لنظام أسماء النطاقات DNS، فيرجى إنشاء سجلات النصوص TXT لسياسة نظام التعرف على هوية المرسِل (SPF) أعلاه.`;
l.DKIM.Results.ThirdParty       = `إذا كنت تستخدم موفر نظام أسماء نطاقات تابع لطرف ثالث، فيرجى اتباع إرشادات إنشاء سجل النص (TXT).`;
l.DKIM.Results.Reference        = `يرجى الرجوع إلى`;
l.DKIM.Results.ResourcesPage    = `صفحة المصادر`;
l.DKIM.Results.Information      = `للحصول على معلومات حول كيفية إنشاء سجلات النص (TXT).`;
l.DKIM.Results.NotUnderstood    = `إذا كنت قد حاولت متابعة هذا ولكن لم تفهم الخطوات التي يجب اتخاذها أو المصطلحات المستخدمة، فمن المستحسن أن تتصل بمؤسسة دعم تكنولوجيا المعلومات، أو مسجل النطاق، أو معلومات تحالف السايبر العالمي (GCA)`;
l.DKIM.Results.Assistance       = `لمزيد من المساعدة`;
l.DKIM.Results.Note             = `الرجاء الإنتباه:`;
l.DKIM.Results.Support          = `يمكن الآن لمفاتيح DKIM دعم مفاتيح 2048 بت. ومع ذلك، لا يمكن لسجلات نصوص (TXT) نظام أسماء النطاقات دعم قيم أكثر من 255 حرفاً. يمكنك تمرير هذا إما عن طريق:`;
l.DKIM.Results.Support2         = `استخدام مفتاح 1024 بت أو،`;
l.DKIM.Results.Support3         = `إنشاء سجل النص ( TXT) متعدد الأسطر`;
l.DKIM.Results.Support4         = `أي مثال لسجل متعدد الأسطر أدناه (يتم إضافة علامات اقتباس لكسر السلسلة إلى أسطر متعددة):`;

l.DMARC.Home.Title              = `ما هو DMARC؟`;
l.DMARC.Home.Final              = `الآن وقد تم إنشاء سياسة نظام التعرف على هوية المرسِل SPF و مفاتيح النطاق لتشفير البريد DKIM وتم تنفيذها. يمكننا الانتقال إلى الخطوة النهائية لإنشاء سياسة مصادقة الرسائل المستندة إلى النطاق، الإبلاغ  والتماثل DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل) هو بروتوكول التحقق من البريد الإلكتروني، والذي يتضمن وظيفة إعداد التقارير التي تسمح للمرسلين والمستقبلين بتحسين ورصد حماية النطاق من البريد الإلكتروني الاحتيالي. سيؤدي تنفيذ مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC  إلى منع مرسلي الرسائل غير المرغوب فيها من خداع عنوان "من" على رسائل البريد. اعتمادا على إعدادات سياسة مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC، حجز أو رفض رسائل البريد الصادرة من خادم بريد غير مصرح به. سيتم حجز جميع الرسائل غير المرغوب فيها ورسائل التصيد الاحتيالي باستخدام اسم نطاق المؤسسة أو حذفها قبل وصولها إلى وجهتها (المستخدم النهائي). يمكن استخدام التقارير المتولدة لأغراض الاستخبارات أو لأغراض إنفاذ القانون، إذا كان النشاط ذا طابع إجرامي.`;
l.DMARC.Home.Stronger           = `سوف يجلب مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC كلا من نظام التعرف على هوية المرسِل SPF و مفاتيح النطاق لتشفير البريد DKIM لتوليد تحقق أقوى للبريد الإلكتروني لنطاق مؤسستك.`;
l.DMARC.Home.ClickNext          = `يرجى النقر على الزر التالي لبدء عملية كيفية إنشاء سياسة مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC.`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `التالي`;

l.DMARC.Policy.Title            = `ما الذي ينبغي أن يكون عليه مستوى سياسة مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC؟`;
l.DMARC.Policy.Levels           = `هناك ثلاثة مستويات للسياسات يمكن تعيينها لمصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC. وتتمثل فيما يلي:`;
l.DMARC.Policy.NoneEx           = `لا شيء - يمكن الإبلاغ عن رسائل بريد مشبوهة محتملة، ولكن يتم إرسال كل البريد إلى البريد الوارد`;
l.DMARC.Policy.QuarantineEx     = `عزل - إخفاق نظام التعرف على هوية المرسِل SPF / سياسة مفاتيح النطاق لتشفير البريد DKIM، ثم يتم إرسال الرسالة إلى مجلد الرسائل المؤذية / غير المرغوب فيها`;
l.DMARC.Policy.RejectEx         = `رفض - إخفاق نظام التعرف على هوية المرسِل SPF / سياسة مفاتيح النطاق لتشفير البريد DKIM، ثم يتم إسقاط الرسالة وعدم تسليمها على الإطلاق`;
l.DMARC.Policy.None             = `لا شيء (موصى به)`;
l.DMARC.Policy.Quarantine       = `عزل`;
l.DMARC.Policy.Reject           = `رفض`;
l.DMARC.Policy.Next             = `التالي`;
l.DMARC.Policy.Recommended1     = `إنه`;
l.DMARC.Policy.Recommended2     = `(مستحسن بشدة.)`;
l.DMARC.Policy.Recommended3     = `لنبدأ بلا، لأن ذلك سيتيح الوقت لمراقبة التقارير التي يتم إنشاؤها وتحديد ما إذا كان سيتم حظر البريد المشروع قبل تبديل سياسة  مصادقة الرسائل المستندة إلى النطاق DMARC إلى العزل أو الرفض.`;
l.DMARC.Policy.Tag              = `سوف يضيف هذا`;
l.DMARC.Policy.Tag2             = `علامة إلى سجل مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC.`;

l.DMARC.Aggregates.Title        = `أين ينبغي إرسال مجموع التقارير؟`;
l.DMARC.Aggregates.Opt          = `هذا اختياري، ولكن موصى به بشدة، وسوف يضيف`;
l.DMARC.Aggregates.Opt2         = `علامة روا لهذه السياسة. إنه`;
l.DMARC.Aggregates.Opt3         = `(موصى به بشدة.)`;
l.DMARC.Aggregates.Opt4         = `أن يضاف ذلك إلى السياسة لأغراض التحليل والرصد. يمكن إرسال التقارير إلى عناوين متعددة.`;
l.DMARC.Aggregates.InputField   = `عنوان البريد الالكتروني`;
l.DMARC.Aggregates.InputInvalid = `يرجى إدخال بريد إلكتروني صحيح`;
l.DMARC.Aggregates.Next         = `التالي`;
l.DMARC.Aggregates.Reports      = `سوف يتم إنشاء مجموع التقارير (تنسيق XML) التي تحتوي على معلومات حول رسائل البريد التي تمر / تفشل ضد نظام التعرف على هوية المرسِل SPF ومفاتيح النطاق لتشفير البريد DKIM. ويتيح ذلك إمكانية التعرف على مشكلات التحقق المحتملة و / أو نشاط الرسائل غير المرغوب فيها لمؤسستك.`;
l.DMARC.Aggregates.Contains     = `وتتضمن التقارير ما يلي`;
l.DMARC.Aggregates.HowToRead    = `كيف تقرأ تقاريرك الأولى لمصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC`;
l.DMARC.Aggregates.By           = `(الجزء 1) بواسطة ايمي غوريل):`;
l.DMARC.Aggregates.Info         = `معلومات مزود خدمة الإنترنت ISP`;
l.DMARC.Aggregates.Desc         = `وصف سجل DMARC لمؤسستك سطر بسطر`;
l.DMARC.Aggregates.Summary      = `ملخص نتائج المصادقة`;
l.DMARC.Aggregates.Important    = `من المهم ملاحظة أن هذه التقارير لن تأتي من مصدر واحد فقط. قد تتلقى 10 إلى 100 (ربما آلاف) من التقارير على أساس يومي (عدد التقارير التي تم إنشاؤها يعتمد على عدد رسائل البريد الإلكتروني المرسلة).`;
l.DMARC.Aggregates.Difficult    = `وهذا سوف يجعل من الصعب مراجعة كل تقرير يدويا، خاصة وأنه في تنسيق XML وبعض التقارير يمكن أن تكون طويلة.`;
l.DMARC.Aggregates.Readable     = `يمكن جعل هذه التقارير قابلة للقراءة البشرية من خلال تطوير محول XML  أو من خلال العمل مع بائع تجاري. سوف يوفر معظم البائعين التجاريين مستودع الخلفية التي من شأنها أن تجعل التقارير قابلة للقراءة البشرية، فضلا عن توفير مستويات مختلفة من التحليل والتوجيه.`;
l.DMARC.Aggregates.Vendors      = `بائع DMARC (شركاء تحالف السايبر العالمي (GCA)):`;

l.DMARC.Forensics.Title         = `أين ينبغي إرسال التقارير القانونية؟`;
l.DMARC.Forensics.Opt           = `هذا اختياري وسوف يضيف`;
l.DMARC.Forensics.Opt2          = `علامة روف إلى السياسة. لن يتم إنشاء تقارير XML هذه إلا إذا كان جانب الاستلام يولد هذه الأنواع من التقارير. هناك فرصة عدم تلقي أي تقارير قانونية. يرجع ذلك إلى قواعد الخصوصية وتبادل البيانات عبر الدول.`;
l.DMARC.Forensics.InputField    = `عنوان البريد الالكتروني`;
l.DMARC.Forensics.InputInvalid  = `يرجى إدخال بريد إلكتروني صحيح`;
l.DMARC.Forensics.Next          = `التالي`;

l.DMARC.Subdomains.Title        = `سياسة مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل DMARC للنطاقات الفرعية؟`;
l.DMARC.Subdomains.Define       = `سوف يحدد هذا مستوى سياسة النطاقات الفرعية لنطاق مؤسستك. إذا كانت هذه العلامة`;
l.DMARC.Subdomains.Define2      = `غير موجودة، فإن السياسة سوف تتبع سياسة نطاق المستوى الأعلى، والتي تم تعريفها في الخطوة الأولى.`;
l.DMARC.Subdomains.None         = `لا شيء`;
l.DMARC.Subdomains.Quarantine   = `عزل`;
l.DMARC.Subdomains.Reject       = `رفض`;
l.DMARC.Subdomains.Next         = `التالي`;
l.DMARC.Subdomains.Policy       = `سياسة النطاق. الخيارات هي:`;
l.DMARC.Subdomains.NoneEx       = `لا شيء - لم يتم اتخاذ أي إجراء، ولكن تم تسجيله في تقرير DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `عزل - يتم وضع علامة على رسالة البريد كرسالة غير مرغوب فيها.`;
l.DMARC.Subdomains.RejectEx     = `رفض - تم حذف الرسالة.`;
l.DMARC.Subdomains.Recommended  = `يوصى بتعيين لا بالنسبة لهذا إذا كانت لديك نطاقات فرعية متعددة. من الأفضل إعداد سياسات DMARC منفصلة لكل نطاق فرعي إلى أن تتماشى جميع النطاقات الفرعية مع سياسة DMARC. ومع ذلك، إذا لم توجد نطاقات فرعية، فمن المستحسن استخدام رفض.`;

l.DMARC.Options.Title           = `(اختياري) الخيارات المتبقية غير مطلوبة ولكن يمكن تعديلها إذا لزم الأمر:`;
l.DMARC.Options.ReportGen       = `1. متى يجب إنشاء تقرير الخطأ`;
l.DMARC.Options.Default         = `افتراضي`;
l.DMARC.Options.ReportPol       = `يحدد سياسة الإبلاغ عن الأخطاء.`;
l.DMARC.Options.DefaultsTo      = `إذا لم يتم تعريفها، افتراضيات من أجل`;
l.DMARC.Options.AllChecks       = `إنشاء تقرير إلى المرسِل MTA إذا فشلت أي عمليات التحقق الأساسية`;
l.DMARC.Options.AnyChecks       = `إنشاء تقرير إلى المرسِل MTA إذا فشلت أي عمليات التحقق الأساسية`;
l.DMARC.Options.OtherValues     = `القيم الأخرى هي:`;
l.DMARC.Options.DKIMFails       = `إنشاء تقرير إذا فشل تحقق مفاتيح النطاق لتشفير البريد DKIM`;
l.DMARC.Options.SPFFails        = `إنشاء تقرير إذا فشل تحقق نظام التعرف على هوية المرسِل (SPF)`;
l.DMARC.Options.Relaxed         = `مسترخ`;
l.DMARC.Options.Strict          = `صارم`;
l.DMARC.Options.DKIMAlign       = `2. وضع انتظام مفاتيح النطاق لتشفير البريد DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `وضع انتظام مفاتيح النطاق لتشفير البريد DKIM. الخيارات هي:`;
l.DMARC.Options.DKIMRelaxed     = `(استرخاء) افتراضي إذا لم يتم تعريفها. يسمح لأي نطاق فرعي محدد في رأس صفحة DKIM.`;
l.DMARC.Options.DKIMStrict      = `(صارمة) يجب أن يتطابق اسم نطاق المرسل مع النطاق في رأس صفحة DKIM بالضبط.`;
l.DMARC.Options.SPFAlign        = `3. وضع الانتظام لنظام التعرف على هوية المرسِل  SPF`;
l.DMARC.Options.SPFAlignOpt     = `وضع الانتظام لنظام التعرف على هوية المرسِل SPF. الخيارات هي:`;
l.DMARC.Options.SPFRelaxed      = `(استرخاء) - افتراضي إذا لم يتم تعريفها.  يسمح لأي نطاق فرعي.`;
l.DMARC.Options.SPFStrict       = `(صارم) - يجب أن يتطابق اسم نطاق المؤسسة في الإيميل من الأمر (في SMTP) ومن: رأس الصفحة (في عنصر البريد) تماماً`;
l.DMARC.Options.Percentage      = `4. النسبة المئوية لموضوع رسالة سياسة مصادقة الرسائل المستندة إلى النطاق واللإبلاغ والتماثل DMARC؟`;
l.DMARC.Options.Filter          = `% من الرسائل التي يتم ترشيحها بواسطة سياسة مصادقة الرسائل المستندة إلى النطاق واللإبلاغ والتماثل DMARC.  يمكن أن يكون أي عدد من 1 إلى 100. افتراضي هو 100، وهو كل الرسائل.`;
l.DMARC.Options.Formats         = `5 - شكل التقارير المتولدة`;
l.DMARC.Options.FormatsEx       = `يحدد شكل التقارير التي يتم إنشاؤها.  قيم الشكل هي:`;
l.DMARC.Options.RFC             = `القيمة الافتراضية إذا لم يتم تعريفها.  يتم تعريف التنسيق بواسطة  RFC  5965.`;
l.DMARC.Options.RFC2            = `يتم تعريف الشكل بواسطة  RFC  5070.`;
l.DMARC.Options.ReportInt       = `6. الفاصل الزمني لإعداد التقارير (بالثواني)`;
l.DMARC.Options.SecondsDefault  = `ثوان (افتراضي)`;
l.DMARC.Options.DigitsOnly      = `يرجى إدخال الأرقام فقط`;
l.DMARC.Options.ReportIntEx     = `يحدد فترات إعداد التقارير بالثواني.  إذا لم يتم تعريفه، الافتراضي هو 86400 ثانية، أو 24 ساعة. يرجى ملاحظة أن التقارير ليست مضمونة ليتم إرسالها عن طريق تلقي وكيل نقل الرسائل MTAs.  تم إرسال التقارير على أفضل وجه ممكن.`;
l.DMARC.Options.HToS            = `محول الساعات إلى الثواني`;
l.DMARC.Options.Hours           = `24 ساعة`;
l.DMARC.Options.To              = `إلى`;
l.DMARC.Options.Seconds         = `86400 ثانية`;
l.DMARC.Options.Finish          = `نهاية`;

l.DMARC.Results.Title           = `في ما يلي نص سجل نصوص (TXT) سياسة نظام التعرف على هوية المرسِل SPF الذي يجب إنشاؤه على خادم نظام أسماء النطاقات DNS.`;
l.DMARC.Results.Display         = `عرض نتيجة سجل نص DMARC`;
l.DMARC.Results.Copy            = `نسخ`;
l.DMARC.Results.Copied          = `تم النسخ!`;
l.DMARC.Results.SPFGuide        = `ارجع إلى دليل سياسة نظام التعرف على هوية المرسِل SPF`;
l.DMARC.Results.DKIMGuide       = `ارجع إلى دليل  DKIM`;
l.DMARC.Results.OwnDNS          = `إذا كنت تمتلك خادم DNS الخاص بك، فقم بإنشاء سجلات TXT لسياسة DMARC أدناه.`;
l.DMARC.Results.ThirdParty      = `إذا كنت تستخدم موفر نظام أسماء نطاقات تابع لطرف ثالث، فيرجى اتباع إرشادات إنشاء سجل النص (TXT). في معظم الحالات كل ما يحتاجونه هو اسم hostname, _dmarc.<domainname> والقيمة النصية، وهي المعلومات الموجودة بين علامتي الاقتباس.`;
l.DMARC.Results.Reference       = `يرجى الرجوع إلى`;
l.DMARC.Results.ResourcesPage   = `صفحة المصادر`;
l.DMARC.Results.Information     = `للحصول على معلومات حول كيفية إنشاء سجلات النص (TXT).`;
l.DMARC.Results.NotUnderstood   = `إذا كنت قد حاولت متابعة هذا ولكن لم تفهم الخطوات التي يجب اتخاذها أو المصطلحات المستخدمة، فمن المستحسن أن تتصل بمؤسسة دعم تكنولوجيا المعلومات، أو مسجل النطاق، أو معلومات تحالف السايبر العالمي (GCA)`;
l.DMARC.Results.Assistance      = `لمزيد من المساعدة`;
l.DMARC.Results.Complete        = `مكتمل`;
l.DMARC.Results.Implemented     = `بعد تنفيذ سجل مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل (DMARC)، يرجى استخدام أداة التحقق / فاحص مصادقة الرسائل المستندة إلى النطاق، والإبلاغ والتماثل (DMARC) أدناه:`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `ليس تماماً هناك بعد.`;
l.DMARC.Results.Wisdom          = `هذا جيد! الحكمة هي الهدف النهائي من كل عمل. لا تتردد في الاستفادة من مصادرنا أعلاه، أو اترك لنا رسالة وبإمكاننا مساعدتك على التنفيذ. من أجل هذا نحن هنا.`;
l.DMARC.Results.Check           = `افحص`;
l.DMARC.Results.Congratulations = `تهانينا`;

export default l;
