let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `DMARCガイド`;

l.Header.Main.SetupDMARC        = `DMARCの設定`;
l.Header.Main.HowItWorks        = `その仕組み`;
l.Header.Main.AboutDMARC        = `DMARCについて`;
l.Header.Main.Resources         = `リソース`;
l.Header.Main.Contact           = `お問い合わせ`;

l.Footer.Main.SetupDMARC        = `DMARCの設定`;
l.Footer.Main.HowItWorks        = `その仕組み`;
l.Footer.Main.AboutDMARC        = `DMARCについて`;
l.Footer.Main.Resources         = `リソース`;
l.Footer.Main.Contact           = `お問い合わせ`;
l.Footer.Main.Copyright         = `Copyright ¬¨¬© 2025 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `プライバシーポリシー`;
l.Footer.Main.Sitemap           = `サイトマップ`;

l.Home.Main.EnterDomain         = `ドメインを入力して`;
l.Home.Main.ToStart             = `DMARCの設定を開始します`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `DMARC設定ガイドへようこそ。この設定ガイドでは、貴社でDMARCのポリシーならびに、Sender Policy Framework (SPF)およびDomainKeys Identified Mail (DKIM)のポリシーを作成する方法を説明します。これら3つのポリシーを全て実装することにより、より強力なメール認証メカニズムを構築し、貴社ブランドを保護することができます。`;
l.Home.Main.FirstStep           = `最初のステップとして、貴社がこれら3つのポリシーのうちのいずれかを使用しているかどうかを確認します。`;
l.Home.Main.InputWarning        = `貴社のメールアドレスのドメインを、メールアドレスの@（アットマーク）の後ろに表示されている通りに正確に入力してください。`;
l.Home.Main.InputExample        = `例えば、メールアドレスが「info@globalcyberalliance.org」の場合、テキストボックスに入力するドメインは「globalcyberalliance.org」になります。`;
l.Home.Main.InputDomain         = `yourdomain.com`;
l.Home.Main.Required            = `ウェブサイトのアドレスは必須項目です`;
l.Home.Main.valid               = `ウェブサイトのアドレスが無効です`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Google AppsおよびOffice 365のユーザー：`;
l.Home.Main.UserWarningText1    = `SPFおよびDKIMを実装するには、リソースページで提供されている`;
l.Home.Main.UserWarningLink     = `クラウドサービスプロバイダー`;
l.Home.Main.UserWarningText2    = `に関する情報に従ってください。完了しましたら、このガイドを利用してDMARCポリシーの実装に進んでください。`;
l.Home.Main.Submit              = `送信`;
l.Home.Main.MoreInfo            = `DMARCに関するより詳しい情報をご希望の場合は、当社が毎月開催しているDMARCウェビナーセッションに一度ご参加になることをお勧めします。このウェビナーではDMARCの基礎を取り扱い、GCA DMARC設定ガイドの概要を簡単にご説明します。`;
l.Home.Main.Webinar             = `GCA DMARCウェビナーセッションは毎月第3水曜日に2度開催されます。下の該当のリンクをクリックして、ご都合の良い日時でご登録ください：`;
l.Home.Main.SelectDate          = `この日付を選択`;

l.ToolSelect.Main.Title         = `次のドメインの結果：`;
l.ToolSelect.Main.ResultStatus  = `下の表は、どのプロトコルが実装済み <i class='fas fa-check-circle' style='color: green'></i> で、どのプロトコルが未実装 <i class='fas fa-times-circle' style='color: red'></i> であるかを示しています。`;
l.ToolSelect.Main.Protocol      = `プロトコルを一つ選択して、設定ガイドを開始してください。`;
l.ToolSelect.Main.ShareButton   = `結果を共有したい場合は、[共有]ボタンをクリックしてください。リンクがクリップボードにコピーされます。`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `DKIMのチェック機能は現在準備中です。ただし、DKIMの行を選択することで、DKIM設定ガイドを利用することは可能です。`;
l.ToolSelect.Main.NoDKIM        = `デフォルトのセレクタリストを用いてお客様のドメインに関連付けられたDKIMレコードを見つけることはできませんでした。`;
l.ToolSelect.Main.NoDKIM1       = `セレクタをご存知の場合は、こちらでDKIMレコードを検索することができます：`;
l.ToolSelect.Main.NoDKIM2       = `あるいは、メールアドレスをご提供いただければ、そのアカウントにメールを送信しますので、そのメールに返信してください。当社側でこのメールをスキャンして、DKIMレコードを確認します。`;
l.ToolSelect.Main.NoDKIMError   = `メールアドレスが有効でないか、ご提供になったドメインに属していません。`;
l.ToolSelect.Main.SelectorMsg   = `セレクタのスキャン中！`;
l.ToolSelect.Main.SelectorError = `セレクタが見つかりませんでした。`;
l.ToolSelect.Main.EmailMsg      = `メールが送信されました！メールに返信してください。メールに返信するとページがリフレッシュされ、セレクタ名が表示されます。`;
l.ToolSelect.Main.InputSelector = `セレクタ`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `送信`;
l.ToolSelect.Main.Select        = `選択`;
l.ToolSelect.Main.Next          = `次へ`;
l.ToolSelect.Main.Share         = `共有`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `DMARCの設定が完了しました`;
l.Complete.Main.check           = `別のドメインについてSPF、DKIM、DMARCを確認しますか？`;
l.Complete.Main.InputDomain     = `yourdomain.com`;
l.Complete.Main.Required        = `ウェブサイトのアドレスは必須項目です`;
l.Complete.Main.valid           = `ウェブサイトのアドレスが無効です`;
l.Complete.Main.Next            = `次へ`;
l.Complete.Main.More            = `DMARCに関する詳細情報`;

l.SPF.Home.Title                = `SPFとは何か？`;
l.SPF.Home.SPF                  = `SPFはSender Policy Frameworkの略です。このポリシーは、貴社のドメインのメール送信を許可されているメールサーバーを定義することで、送信者アドレスのなりすましを防止する役割があります。SPFでメールサーバーが定義されていない場合、メッセージは拒否されるか、バウンスメールが返されます。`;
l.SPF.Home.Start                = `SPFポリシーの作成手順を開始するには、[次へ]ボタンをクリックしてください。`;
l.SPF.Home.Next                 = `次へ`;

l.SPF.Authorization.Title       = `以下に挙げるサーバーは、貴社のドメインのメール送信を許可されていますか？`;
l.SPF.Authorization.First       = `ポリシーの最初の部分では、ドメインの送信先メールサーバー（MX）であり、当該ドメインのメール送信も許可されているメールサーバーを定義します。`;
l.SPF.Authorization.Current     = `下のリストには、貴社と関連付けられたメールサーバーに対応する現在のDNSレコードが含まれています。`;
l.SPF.Authorization.Recommended = `上のリストはお客様のドメインで許可されたサーバーであると考えられるので、はいを選択することを強く推奨します。これにより、SPFポリシーにmxタグが追加されます。`;
l.SPF.Authorization.Yes         = `はい（推奨）`;
l.SPF.Authorization.No          = `いいえ`;
l.SPF.Authorization.Missing     = `リストから漏れているシステムがある場合、または標準メールサーバーが表示されていない場合は、次のオプションでこれらのシステムを追加してください。`;
l.SPF.Authorization.Next        = `次へ`;

l.SPF.Servers.Title             = `このドメインのメールの配信または中継に使用される追加のサーバーがドメイン内に存在しますか？`;
l.SPF.Servers.Possible          = `貴社のDNSレコード内で、貴社の全てのメールサーバーが定義されていない可能性もあります。テスト用のサーバーや、メールシステム内蔵のアプリケーションがこれに該当する可能性があります。その場合は、下のボックスに追加のサーバードメインかIPアドレスを追加してください。`;
l.SPF.Servers.Others            = `この設定は、メールサーバー以外に当該ドメインのメール送信を許可された別の内部システムが存在する場合のみ使用してください。`;
l.SPF.Servers.Added             = `システムを追加すると、SPFポリシーに‚Äö√Ñ√≤a‚Äö√Ñ√¥タグが追加されます。`;
l.SPF.Servers.InputField        = `サーバー名またはIP`;
l.SPF.Servers.InputInvalid      = `完全修飾ドメイン名かIPアドレスを入力してください`;
l.SPF.Servers.Next              = `次へ`;

l.SPF.Domains.Title             = `貴社のメールを配信または中継する外部ドメインは存在しますか？`;
l.SPF.Domains.Include           = `このセクションは`;
l.SPF.Domains.Include2          = `include`;
l.SPF.Domains.Include3          = `タグをSPFポリシーに追加します。`;
l.SPF.Domains.Trusted           = `この設定は、貴社ドメインのメッセージ送信を許可された信頼できる外部ドメインが存在する場合のみ使用してください。例えば、CRMシステム、クラウドメールプロバイダー（Google、Office 365、Yahoo!）、クラウドセキュリティプロバイダーなど。`;
l.SPF.Domains.Note              = `注意：クラウドサービスプロバイダーをご利用の場合、プロバイダーに適切な設定値を確認する必要があります。`;
l.SPF.Domains.InputField        = `ドメイン名またはIP`;
l.SPF.Domains.InputInvalid      = `完全修飾ドメイン名かIPアドレスを入力してください`;
l.SPF.Domains.Next              = `次へ`;

l.SPF.Strict.Title              = `サーバーでどの程度厳格にメールを処理しますか？`;
l.SPF.Strict.Receiving          = `これはSPFポリシーの最後のセクションで、受信サーバーでどの程度厳格にメールを処理するかを定義します。`;
l.SPF.Strict.SoftFail           = `Soft Fail（推奨）`;
l.SPF.Strict.Fail               = `Fail`;
l.SPF.Strict.Neutral            = `Neutral`;
l.SPF.Strict.AllowAll           = `Allow All`;
l.SPF.Strict.Next               = `次へ`;
l.SPF.Strict.Options            = `オプション：`;
l.SPF.Strict.FailEx             = `Failまたは`;
l.SPF.Strict.FailEx2            = `は、ドメインメールサーバー（ならびに、aおよびincludeのセクションに記述されているもの）のみが当該ドメインのメール送信を許可されることを意味します。その他のサーバーは全て禁止されています。`;
l.SPF.Strict.SoftEx             = `Soft Failまたは `;
l.SPF.Strict.SoftEx2            = `は、ドメインメールサーバー（ならびに、aおよびincludeのセクションに記述されているもの）のみが当該ドメインのメール送信を許可されることを意味しますが、移行措置として使用されます。その他のサーバーは全て禁止されています。`;
l.SPF.Strict.NeutralEx          = `Neutralまたは`;
l.SPF.Strict.NeutralEx2         = `は、正当性に関しては何も言えないということを明示的に意味します。`;
l.SPF.Strict.AllowEx            = `Allow Allまたは`;
l.SPF.Strict.AllowEx2           = `は、全てのホストが当該ドメインのメールを送信できることを意味します。`;
l.SPF.Strict.Warning1           = `この設定は`;
l.SPF.Strict.Warning2           = `絶対に`;
l.SPF.Strict.Warning3           = `使用しないでください`;
l.SPF.Strict.Recommended        = `Soft Failをご使用になることをお勧めします。`;
l.SPF.Strict.MarkMail           = `この設定により、定義した条件を満たさないメールに「non-compliant（不遵守）」のフラグが付きます。`;

l.SPF.Results.Title             = `以下は、ご利用のDNSサーバー上で作成すべきSPF TXTレコードです。`;
l.SPF.Results.Copy              = `コピー`;
l.SPF.Results.Copied            = `コピーされました！`;
l.SPF.Results.DMARCGuide        = `DMARCガイドに移動する`;
l.SPF.Results.DKIMGuide         = `DKIMガイドに進む`;
l.SPF.Results.OwnDNS            = `自社でDNSサーバーをお持ちの場合は、上のSPFポリシーに対応するTXTレコードを作成してください。`;
l.SPF.Results.ThirdParty        = `サードパーティのDNSプロバイダーをご利用の場合は、当該プロバイダーの指示に従ってTXTレコードを作成してください。`;
l.SPF.Results.Reference         = `TXTレコードの作成方法に関する情報は`;
l.SPF.Results.ResourcesPage     = `リソースページ`;
l.SPF.Results.Information       = `をご覧ください。`;
l.SPF.Results.NotUnderstood     = `こちらの手順を試そうとしたが具体的な手順や使用されている用語が理解できないという場合は、ITサポート組織、ドメインレジストラ、またはGCA Info`;
l.SPF.Results.Assistance        = `にサポートを依頼してください。`;
l.SPF.Results.Implemented       = `SPFレコードの実装が完了したら、以下のSPF検証ツールをご利用ください：`;
l.SPF.Results.YourDomain        = `yourdomain.com`;
l.SPF.Results.Check             = `検証`;
l.SPF.Results.SuccessDKIM       = `DKIMに移動`;
l.SPF.Results.SuccessDMARC      = `DMARCに移動`;
l.SPF.Results.NotQuite          = `まだ設定が完了していません。`;
l.SPF.Results.Wisdom            = `問題ありません！あらゆる行動が英知の獲得につながります。上記のリソースをご利用になるか、当社までお気軽にメッセージを頂ければ、私たちが実装のお手伝いをいたします。是非、ご連絡をお持ちしております。`;

l.DKIM.Home.Title               = `DKIMとは何か？`;
l.DKIM.Home.Mechanism           = `DKIMはDomainKeys Identified Mailの略です。DKIMとは、メールメッセージに関連付けられたドメインのアイデンティティ検証を目的に開発されたメカニズムです。これは、非対称暗号を用いた認証により実現されます。DKIMは基本的に、メッセージのヘッダー内に電子署名を追加することによりメールメッセージを認証します。 重要な注意点として、DKIMはメールメッセージのフィルタリングを実施するものではありません。DKIMにより、スパムフィルターが送信されたメールの信頼性を判断することが可能になります。`;
l.DKIM.Home.ClickNext           = `DKIMポリシーの作成手順を開始するには、[次へ]ボタンをクリックしてください。`;
l.DKIM.Home.YourDomain          = `yourdomain.com`;
l.DKIM.Home.Next                = `次へ`;

l.DKIM.Selector.Title           = `セレクタ`;
l.DKIM.Selector.Name            = `セレクタはDKIMレコードの名前を定義するものです。セレクタ名は何でも結構ですが、以下にいくつかの選択肢をご用意しています。`;
l.DKIM.Selector.Define          = `これは、DKIMキーを設定するために作成するTXTレコードの名前を定義するものです。次のように表示されます：`;
l.DKIM.Selector.Example1        = `セレクタ`;
l.DKIM.Selector.Example2        = `ドメイン名`;
l.DKIM.Selector.Next            = `次へ`;

l.DKIM.Key.Title                = `公開鍵`;
l.DKIM.Key.Tag                  = `この設定により、予め生成された公開鍵ファイル中の公開鍵文字列である'p'タグが追加されます。`;
l.DKIM.Key.Keys                 = `こちらに公開鍵を入力`;
l.DKIM.Key.Next                 = `次へ`;
l.DKIM.Key.Creating             = `DKIMキーの作成：`;
l.DKIM.Key.TwoParts             = `DKIMには、秘密鍵と公開鍵という二つの要素があります。DKIM用の鍵の作成は、貴社のメールサービスを誰が提供しているかによって変わります。`;
l.DKIM.Key.External             = `メールとDNSの両方で外部サービスをご利用の場合は、メールプロバイダーがお客様に公開鍵を提供する必要があります。その後、この公開鍵はDNSのTXTレコードとして公開されます。DNSプロバイダーの多くはTXTレコードのサイズを255文字に制限しているため、サイズを増やすため、またはTXTレコードを作成するためにプロバイダーの協力が必要になる場合があります。秘密鍵はメールプロバイダーにより保持され、通常貴社側には提供されません。`;
l.DKIM.Key.Internal             = `貴社が独自のメールサーバーをお持ちの場合、DKIMキーの生成はITスタッフ（またはDKIM実装の責任者）が行う必要があります。インターネット上でさまざまなDKIMキー生成ツールが利用可能です。これらのツールを使用する場合は、必ずキーを生成後に削除し、お客様サイドでファイルにコピーするオプションがあるものをご使用ください。`;
l.DKIM.Key.OpenDKIM             = `サードパーティを利用せずにキーを生成する場合は、OpenDKIMというオープンソースプロジェクト`;
l.DKIM.Key.OpenDKIM2            = `が利用できます。OpenDKIMには、DKIMキーの作成を支援するさまざまなツールが用意されています。`;
l.DKIM.Key.OpenSSL              = `DKIMキーを生成するもう一つのオプションとして、OpenSSLを利用する方法があります。OpenSSLの最新バージョンを使用して、次のコマンドを入力して秘密鍵を生成します：`;
l.DKIM.Key.OpenSSL2             = `これにより、次の名称の秘密鍵ファイルが生成されます：`;
l.DKIM.Key.OpenSSL3             = `次に、次のコマンドを入力して公開鍵を生成します：`;
l.DKIM.Key.OpenSSL4             = `このコマンドは公開鍵`;
l.DKIM.Key.OpenSSL5             = `を生成するもので、先に生成した秘密鍵に基づいて行われます`;
l.DKIM.Key.OpenSSL6             = `DKIMの秘密鍵を、DKIMのインストールにより指定される場所に移動します。必ずアクセス制限のかかったフォルダーに移動してください。`;

l.DKIM.Results.Title            = `以下は、ご利用のDNSサーバー上で作成すべきDKIM TXTレコードです。`;
l.DKIM.Results.Copy             = `コピー`;
l.DKIM.Results.Copied           = `コピーされました！`;
l.DKIM.Results.SPFGuide         = `SPFガイドに戻る`;
l.DKIM.Results.DMARCGuide       = `DMARCガイドに進む`;
l.DKIM.Results.OwnDNS           = `自社でDNSサーバーをお持ちの場合は、上のDKIMポリシーに対応するTXTレコードを作成してください。`;
l.DKIM.Results.ThirdParty       = `サードパーティのDNSプロバイダーをご利用の場合は、当該プロバイダーの指示に従ってTXTレコードを作成してください。`;
l.DKIM.Results.Reference        = `TXTレコードの作成方法に関する情報は`;
l.DKIM.Results.ResourcesPage    = `リソースページ`;
l.DKIM.Results.Information      = `をご覧ください。`;
l.DKIM.Results.NotUnderstood    = `こちらの手順を試そうとしたが具体的な手順や使用されている用語が理解できないという場合は、ITサポート組織、ドメインレジストラ、またはGCA Info`;
l.DKIM.Results.Assistance       = `にサポートを依頼してください。`;
l.DKIM.Results.Note             = `注意：`;
l.DKIM.Results.Support          = `DKIMキーは現在、2048ビットのキーをサポートしています。ただし、DNS TXTレコードは255文字を超える値をサポートしていません。以下のいずれかの方法でこの問題を回避できます：`;
l.DKIM.Results.Support2         = `1024ビットキーを使用する`;
l.DKIM.Results.Support3         = `複数行のTXTレコードを作成する`;
l.DKIM.Results.Support4         = `以下は複数行レコードの一例です（文字列を複数行に分割するために二重引用符が追加されます）：`;

l.DMARC.Home.Title              = `DMARCとは何か？`;
l.DMARC.Home.Final              = `以上で、SPFおよびDKIMポリシーが作成・実装されました。最終ステップであるDMARCポリシーの作成に移ります。`;
l.DMARC.Home.Protocol           = `DMARC (Domain-based Message Authentication, Reporting & Conformance)はメール認証プロトルの一つで、レポート機能を備えており、送信者・受信者共になりすましメールに対するドメインの防御力を高め、これを監視することが可能になります。DMARCを実装することで、スパマーによるメールメッセージの「送信元」アドレスの詐称を防ぎます。DMARCポリシーの設定に従い、権限のないメールサーバーから送られたメールメッセージは隔離または拒否されます。貴社のドメイン名を利用しているスパムメッセージおよびフィッシングメッセージは全て、送信対象者（エンドユーザー）に到達する前に隔離または削除されます。生成されたレポートはインテリジェンスに利用可能で、活動の性質が犯罪的なものである場合は、法律の執行目的で利用することも可能です。`;
l.DMARC.Home.Stronger           = `DMARCはSPFとDKIMを融合し、貴社のドメインを保護するより強力なメール認証を実現します。`;
l.DMARC.Home.ClickNext          = `DMARCポリシーの作成手順を開始するには、[次へ]ボタンをクリックしてください。`;
l.DMARC.Home.YourDomain         = `yourdomain.com`;
l.DMARC.Home.Next               = `次へ`;

l.DMARC.Policy.Title            = `DMARCのポリシーレベルをどのように設定しますか？`;
l.DMARC.Policy.Levels           = `DMARCでは、以下の3つのポリシーレベルを設定することができます：`;
l.DMARC.Policy.NoneEx           = `None - 疑わしい可能性のあるメールメッセージを報告しますが、メールは全て受信箱に配信されます`;
l.DMARC.Policy.QuarantineEx     = `Quarantine - SPF/DKIMポリシーで認証に失敗した場合、メッセージはスパム/迷惑メールフォルダーに振り分けられます`;
l.DMARC.Policy.RejectEx         = `Reject - SPF/DKIMポリシーで認証に失敗した場合、メッセージは削除され、配信されることはありません`;
l.DMARC.Policy.None             = `None（推奨）`;
l.DMARC.Policy.Quarantine       = `Quarantine`;
l.DMARC.Policy.Reject           = `Reject`;
l.DMARC.Policy.Next             = `次へ`;
l.DMARC.Policy.Recommended1     = `最初はNoneに設定することを`;
l.DMARC.Policy.Recommended2     = `強く推奨します。`;
l.DMARC.Policy.Recommended3     = `これにより、DMARCポリシーをQuarantineまたはRejectに切り替える前に、生成されるレポートを監視し、正規のメールがブロックされるかどうかを判断する時間が得られます。`;
l.DMARC.Policy.Tag              = `これにより、DMARCレコードに`;
l.DMARC.Policy.Tag2             = `タグが追加されます。`;

l.DMARC.Aggregates.Title        = `集計レポートの送信先はどこにしますか？`;
l.DMARC.Aggregates.Opt          = `この設定はオプションですが、強く推奨されており、ポリシーに`;
l.DMARC.Aggregates.Opt2         = `ruaタグが追加されます。分析およびモニタリングのために、このタグを追加することが`;
l.DMARC.Aggregates.Opt3         = `強く推奨されています。`;
l.DMARC.Aggregates.Opt4         = `レポートは複数のアドレスに送信可能です。`;
l.DMARC.Aggregates.InputField   = `メールアドレス`;
l.DMARC.Aggregates.InputInvalid = `有効なメールアドレスを入力してください`;
l.DMARC.Aggregates.Next         = `次へ`;
l.DMARC.Aggregates.Reports      = `どのメールメッセージがSPFおよびDKIMで通過または認証失敗したかに関する情報を含む、集計レポート（XML形式）が生成されます。これにより、貴社における潜在的な認証の問題やスパム活動を可視化することができます。`;
l.DMARC.Aggregates.Contains     = `レポートには以下の情報が含まれます`;
l.DMARC.Aggregates.HowToRead    = `はじめてのDMARCレポートの読み方`;
l.DMARC.Aggregates.By           = `（パート1） Amy Gorrell執筆）：`;
l.DMARC.Aggregates.Info         = `ISP情報`;
l.DMARC.Aggregates.Desc         = `貴社のDMARCレコードの各行の説明`;
l.DMARC.Aggregates.Summary      = `認証結果の要約`;
l.DMARC.Aggregates.Important    = `重要な点として、これらのレポートのソースは一つだけではないということにご注意ください。毎日、10～100件（数千件に上る可能性あり）のレポートが届く可能性があります（生成されるレポート数は、送信されるメールの数に依存します）。`;
l.DMARC.Aggregates.Difficult    = `この結果、特に形式がXMLで、一部非常に長いレポートも存在するため、各レポートを手動で確認することは困難になります。`;
l.DMARC.Aggregates.Readable     = `これらのレポートは、XMLコンバーターを開発することにより、または商用ベンダーの協力を得て、人間が読める形にすることができます。商用ベンダーの多くは、レポートを人間が読める形にするバックエンドレポジトリーを提供するだけでなく、様々なレベルの分析やガイダンスも提供します。`;
l.DMARC.Aggregates.Vendors      = `DMARCベンダー（GCAパートナー）：`;

l.DMARC.Forensics.Title         = `フォレンジックレポートの送信先はどこにしますか？`;
l.DMARC.Forensics.Opt           = `この設定はオプションであり、ポリシーに`;
l.DMARC.Forensics.Opt2          = `rufタグが追加されます。これらのXML形式のレポートは、受信側がこの種のレポートを生成する場合にのみ生成されます。フォレンジックレポートが全く届かない可能性もあります。これは、国毎のプライバシーおよびデータ共有に関する規制の影響を受けます。`;
l.DMARC.Forensics.InputField    = `メールアドレス`;
l.DMARC.Forensics.InputInvalid  = `有効なメールアドレスを入力してください`;
l.DMARC.Forensics.Next          = `次へ`;

l.DMARC.Subdomains.Title        = `サブドメイン用のDMARCポリシーはどのように設定しますか？`;
l.DMARC.Subdomains.Define       = `これは、貴社ドメインのサブドメインを対象としたポリシーレベルを定義するものです。このタグ`;
l.DMARC.Subdomains.Define2      = `が存在しない場合は、最初のステップで定義したトップレベルドメインのポリシーに従います。`;
l.DMARC.Subdomains.None         = `None`;
l.DMARC.Subdomains.Quarantine   = `Quarantine`;
l.DMARC.Subdomains.Reject       = `Reject`;
l.DMARC.Subdomains.Next         = `次へ`;
l.DMARC.Subdomains.Policy       = `ドメインポリシーのオプションは以下の通りです：`;
l.DMARC.Subdomains.NoneEx       = `None - 処理は一切行われませんが、DMARCレポートに記録されます。`;
l.DMARC.Subdomains.QuarantineEx = `Quarantine - メールメッセージにスパムのフラグが付きます。`;
l.DMARC.Subdomains.RejectEx     = `Reject - メッセージが削除されます。`;
l.DMARC.Subdomains.Recommended  = `サブドメインが複数存在する場合は、Noneに設定することをお勧めします。全てのサブドメインがDMARCポリシーに準拠するまでは、サブドメイン毎に個別のDMARCポリシーを設定するのが最も推奨されるやり方です。ただし、サブドメインが存在しない場合は、Rejectの使用をお勧めします。`;

l.DMARC.Options.Title           = `（オプション）残りのオプションは必須ではありませんが、必要に応じて調整することができます：`;
l.DMARC.Options.ReportGen       = `1. エラーレポートが生成されるタイミング`;
l.DMARC.Options.Default         = `既定値`;
l.DMARC.Options.ReportPol       = `エラーレポートのポリシーを定義します。`;
l.DMARC.Options.DefaultsTo      = `定義されていない場合の既定値：`;
l.DMARC.Options.AllChecks       = `下層での認証に失敗した場合に送信側MTAに対するレポートを生成します`;
l.DMARC.Options.AnyChecks       = `下層での認証に失敗した場合に送信側MTAに対するレポートを生成します`;
l.DMARC.Options.OtherValues     = `その他の値の説明：`;
l.DMARC.Options.DKIMFails       = `DKIM認証が失敗した場合にレポートを生成`;
l.DMARC.Options.SPFFails        = `SPF認証が失敗した場合にレポートを生成`;
l.DMARC.Options.Relaxed         = `Relaxed`;
l.DMARC.Options.Strict          = `Strict`;
l.DMARC.Options.DKIMAlign       = `2. DKIMアライメントモード`;
l.DMARC.Options.DKIMAlignOpt    = `DKIMアライメントモードのオプションは以下の通りです：`;
l.DMARC.Options.DKIMRelaxed     = `(relaxed) 定義されていない場合の既定値。DKIMヘッダーで定義されている全てのサブドメインを許可します。`;
l.DMARC.Options.DKIMStrict      = `(strict) 送信者のドメイン名がDKIMヘッダー内のドメインと正確に一致しなければなりません。`;
l.DMARC.Options.SPFAlign        = `3. SPFアライメントモード`;
l.DMARC.Options.SPFAlignOpt     = `SPFアライメントモードのオプションは以下の通りです：`;
l.DMARC.Options.SPFRelaxed      = `(relaxed) - 定義されていない場合の既定値。全てのサブドメインを許可します。`;
l.DMARC.Options.SPFStrict       = `(strict) - （SMTPの）MAIL FROMコマンド内の貴社ドメイン名と、（メールの）ヘッダーのFrom:のドメインが正確に一致しなければなりません。`;
l.DMARC.Options.Percentage      = `4. DMARCポリシーが適用されるメッセージの割合（％）は？`;
l.DMARC.Options.Filter          = `%のメッセージがDMARCポリシーによるフィルタリングの対象となります。1から100までのいずれかの数字を使用してください。既定値は100で、全てのメッセージが対象となります。`;
l.DMARC.Options.Formats         = `5. 生成されるレポートの形式`;
l.DMARC.Options.FormatsEx       = `生成されるレポートの形式を定義します。形式の設定値は以下の通りです：`;
l.DMARC.Options.RFC             = `定義されていない場合の既定値。形式はRFC 5965により定義されます。`;
l.DMARC.Options.RFC2            = `形式はRFC 5070により定義されます。`;
l.DMARC.Options.ReportInt       = `6. レポート間隔（秒単位）`;
l.DMARC.Options.SecondsDefault  = `秒（既定値）`;
l.DMARC.Options.DigitsOnly      = `数字のみを入力してください`;
l.DMARC.Options.ReportIntEx     = `レポート間隔を秒単位で定義します。定義されていない場合、既定値は86400秒、すなわち24時間になります。受信側MTAが必ずレポートを送信するという保証はありませんのご注意ください。レポートの送信はベストエフォート方式となります。`;
l.DMARC.Options.HToS            = `時間→秒コンバーター`;
l.DMARC.Options.Hours           = `24時間`;
l.DMARC.Options.To              = `＝`;
l.DMARC.Options.Seconds         = `86400秒`;
l.DMARC.Options.Finish          = `終了`;

l.DMARC.Results.Title           = `以下は、ご利用のDNSサーバー上で作成すべきDMAR TXTレコードです。`;
l.DMARC.Results.Display         = `DMARC TXTレコードの結果を表示`;
l.DMARC.Results.Copy            = `コピー`;
l.DMARC.Results.Copied          = `コピーされました！`;
l.DMARC.Results.SPFGuide        = `SPFガイドに戻る`;
l.DMARC.Results.DKIMGuide       = `DKIMガイドに戻る`;
l.DMARC.Results.OwnDNS          = `独自のDNSサーバーをお持ちの場合は、以下のDMARCポリシーに対するTXTレコードを作成してください。`;
l.DMARC.Results.ThirdParty      = `サードパーティのDNSプロバイダーをご利用の場合は、当該プロバイダーの指示に従ってTXTレコードを作成してください。ほとんどの場合、 プロバイダーが必要とするのはhostname、_dmarc.<domainname>およびテキスト値のみであり、この情報は引用符で囲まれた部分に記述されています。`;
l.DMARC.Results.Reference       = `TXTレコードの作成方法に関する情報は`;
l.DMARC.Results.ResourcesPage   = `リソースページ`;
l.DMARC.Results.Information     = `をご覧ください。`;
l.DMARC.Results.NotUnderstood   = `こちらの手順を試そうとしたが具体的な手順や使用されている用語が理解できないという場合は、ITサポート組織、ドメインレジストラ、またはGCA Info`;
l.DMARC.Results.Assistance      = `にサポートを依頼してください。`;
l.DMARC.Results.Complete        = `完了`;
l.DMARC.Results.Implemented     = `DMARCレコードの実装が完了したら、以下のDMARC検証ツールをご利用ください：`;
l.DMARC.Results.YourDomain      = `yourdomain.com`;
l.DMARC.Results.NotQuite        = `まだ設定が完了していません。`;
l.DMARC.Results.Wisdom          = `問題ありません！あらゆる行動が英知の獲得につながります。上記のリソースをご利用になるか、当社までお気軽にメッセージを頂ければ、私たちが実装のお手伝いをいたします。是非、ご連絡をお持ちしております。`;
l.DMARC.Results.Check           = `検証`;
l.DMARC.Results.Congratulations = `おめでとうございます`;

export default l;
