let l = {
        Global: {Main: {}},
        Header: {Main: {}},
        Footer: {Main: {}},
        Home: {Main: {}},
        ToolSelect: {Main: {}, DMARC: {}},
        Complete: {Main: {}},
        SPF: {Home: {},Authorization: {},Servers: {},Domains: {},Strict: {},Results: {}},
        DKIM: {Home: {},Selector: {},Key: {},Results: {}},
        DMARC: {Home: {},Policy: {},Aggregates: {},Forensics: {},Subdomains: {},Options: {},Results: {}}
    };

l.Global.Main.Title             = `Guía de DMARC`;

l.Header.Main.SetupDMARC        = `CONFIGURACIÓN DE DMARC`;
l.Header.Main.HowItWorks        = `CÓMO FUNCIONA`;
l.Header.Main.AboutDMARC        = `SOBRE DMARC`;
l.Header.Main.Resources         = `RECURSOS`;
l.Header.Main.Contact           = `CONTACTO`;

l.Footer.Main.SetupDMARC        = `Configuración de DMARC`;
l.Footer.Main.HowItWorks        = `Cómo funciona`;
l.Footer.Main.AboutDMARC        = `Sobre DMARC`;
l.Footer.Main.Resources         = `Recursos`;
l.Footer.Main.Contact           = `Contacto`;
l.Footer.Main.Copyright         = `Copyright © 2025 Global Cyber Alliance`;
l.Footer.Main.PrivacyPolicy     = `Política de Privacidad`;
l.Footer.Main.Sitemap           = `Mapa del sitio`;

l.Home.Main.EnterDomain         = `Introduzca su dominio`;
l.Home.Main.ToStart             = `para empezar a configurar DMARC`;
l.Home.Main.TagLine             = ``;
l.Home.Main.Welcome             = `Bienvenido a la guía de configuración de DMARC. El objetivo de esta guía de configuración es guiar a su organización a través del proceso de creación de una política DMARC, así como de otras políticas de Convenio de Remitente (SPF de sus siglas en inglés) y de DomainKeys Identified Mail (DKIM). Implementando estas tres políticas, su organización contará con un robusto mecanismo de autenticación de correo electrónico que le ayudará a proteger su marca.`;
l.Home.Main.FirstStep           = `El primer paso es confirmar si su organización está ya usando alguna de estas tres políticas.`;
l.Home.Main.InputWarning        = `Por favor, introduzca el dominio asociado a la dirección de correo electrónico de su organización, tal y como aparece después del símbolo @.`;
l.Home.Main.InputExample        = `Por ejemplo, si su dirección de correo electrónico fuera domainsecurityscanner@globalcyberalliance.org, tendría que introducir globalcyberalliance.org en el cuadro de texto.`;
l.Home.Main.InputDomain         = `sudominio.com`;
l.Home.Main.Required            = `La dirección del sitio web es obligatoria`;
l.Home.Main.valid               = `La dirección del sitio web no es válida`;
l.Home.Main.PleaseNote          = ``;
l.Home.Main.UserWarningHeader   = `Usuarios de Office 365 y Google Apps:`;
l.Home.Main.UserWarningText1    = `Por favor, siga la información proporcionada en la página de recursos de los`;
l.Home.Main.UserWarningLink     = `Proveedores de Servicios en la Nube`;
l.Home.Main.UserWarningText2    = `para implementar políticas SPF y DKIM. Una vez completado el proceso anterior, por favor, utilice esta guía para continuar con la implementación de la política DMARC.`;
l.Home.Main.Submit              = `Enviar`;
l.Home.Main.MoreInfo            = `Si desea obtener más información sobre DMARC, le invitamos a asistir a uno de nuestros seminarios webs mensuales. En estos seminarios explicamos los conceptos básicos sobre el uso de DMARC y repasamos brevemente la guía de configuración de DMARC de la GCA.`;
l.Home.Main.Webinar             = `El seminario web mensual sobre DMARC tiene lugar dos veces al día el tercer miércoles de cada mes. Por favor, regístrese al seminario que mejor le venga haciendo clic en el enlace correspondiente:`;
l.Home.Main.SelectDate          = `Seleccionar esta fecha`;

l.ToolSelect.Main.Title         = `Resultados para`;
l.ToolSelect.Main.ResultStatus  = `La siguiente tabla indica qué protocolos han sido <i class='fas fa-check-circle' style='color: green'></i> o no <i class='fas fa-times-circle' style='color: red'></i> implementados.`;
l.ToolSelect.Main.Protocol      = `Por favor, seleccione uno de los protocolos para empezar con la guía de configuración.`;
l.ToolSelect.Main.ShareButton   = `Si desea compartir los resultados haga clic en el botón de compartir, se copiará un enlace en su portapapeles.`;
l.ToolSelect.Main.Selecting     = ``;
l.ToolSelect.Main.DKIMWarning   = `La comprobación del estado de implantación de DKIM todavía está en desarrollo. No obstante, la guía de configuración DKIM sigue disponible seleccionando la fila DKIM.`;
l.ToolSelect.Main.NoDKIM        = `Con base en una lista de selectores predeterminados, no hemos podido encontrar un registro DKIM asociado a su dominio:`;
l.ToolSelect.Main.NoDKIM1       = `Si conoce el selector adecuado puede buscar el registro DKIM aquí:`;
l.ToolSelect.Main.NoDKIM2       = `También puede facilitarnos una dirección de correo electrónico a la que podamos enviarle un correo que pueda responder, y así nosotros buscar el registro DKIM asociado, en caso de existir.`;
l.ToolSelect.Main.NoDKIMError   = `El correo electrónico no es válido o no pertenece al dominio indicado previamente.`;
l.ToolSelect.Main.SelectorMsg   = `¡Buscando selector!`;
l.ToolSelect.Main.SelectorError = `No se ha encontrado ningún selector.`;
l.ToolSelect.Main.EmailMsg      = `¡Correo enviado! Por favor, responda al correo que le hemos enviado. Una vez recibamos su respuesta, esta página se actualizará y le indicará el nombre del selector asociado.`;
l.ToolSelect.Main.InputSelector = `Selector`;
l.ToolSelect.Main.InputEmail    = `user@example.com`;
l.ToolSelect.Main.Submit        = `Enviar`;
l.ToolSelect.Main.Select        = `Seleccionar`;
l.ToolSelect.Main.Next          = `Siguiente`;
l.ToolSelect.Main.Share         = `Compartir`;

l.ToolSelect.DMARC.RedX         = ``;
l.ToolSelect.DMARC.RedExcl      = ``;
l.ToolSelect.DMARC.RedCheck     = ``;
l.ToolSelect.DMARC.AmberExcl    = ``;
l.ToolSelect.DMARC.AmberCheck   = ``;
l.ToolSelect.DMARC.GreenCheck   = ``;

l.Complete.Main.Title           = `Ha completado el proceso de configuración de DMARC`;
l.Complete.Main.check           = `¿Quiere comprobar si otro dominio tiene implementadas políticas SPF, DKIM o DMARC?`;
l.Complete.Main.InputDomain     = `sudominio.com`;
l.Complete.Main.Required        = `La dirección del sitio web es obligatoria`;
l.Complete.Main.valid           = `La dirección del sitio web no es válida`;
l.Complete.Main.Next            = `Siguiente`;
l.Complete.Main.More            = `Más sobre DMARC`;

l.SPF.Home.Title                = `¿Qué es SPF?`;
l.SPF.Home.SPF                  = `Las siglas SPF responden a Convenio de Remitentes (Sender Policy Framework en inglés). Esta política vela por la integridad de la dirección de correo del remitente, evitando que se utilicen, de forma fraudulenta, servidores de correo que no estén asociados al dominio de la organización. Si el servidores de correo no está definido en el SPF el mensaje es rechazado o devuelto.`;
l.SPF.Home.Start                = `Haga clic en el botón siguiente para iniciar el proceso que le ayudará a crear una política SPF.`;
l.SPF.Home.Next                 = `Siguiente`;

l.SPF.Authorization.Title       = `¿Están autorizados los servidores de correo listados a continuación para enviar correos en nombre del dominio de la organización?`;
l.SPF.Authorization.First       = `La primera parte de la política se utiliza para definir los servidores de correo entrante (MXes) del dominio que también están autorizados a enviar correos en nombre del mismo dominio.`;
l.SPF.Authorization.Current     = `La siguiente lista contiene los registros DNS actuales de los proveedores de correo asociados a su organización.`;
l.SPF.Authorization.Recommended = `Se recomienda que seleccione Sí  </bx> , ya que la lista de arriba debería contener todos los proveedores autorizados en su dominio. Al hacerlo, se agregará la etiqueta mx a la política SPF.`;
l.SPF.Authorization.Yes         = `Sí (recomendado)`;
l.SPF.Authorization.No          = `No`;
l.SPF.Authorization.Missing     = `Si cree que falta algún sistema en la lista o si su proveedor no es uno de los estándar, inclúyalo en la siguiente opción.`;
l.SPF.Authorization.Next        = `Siguiente`;

l.SPF.Servers.Title             = `¿Existe algún otro servidor dentro del dominio que pueda entregar o reenviar correos?`;
l.SPF.Servers.Possible          = `Cabe la posibilidad de que no todos los servidores de correo de la organización estén definidos en los registros DNS de la organización. Puede pasar, por ejemplo, con servidores de prueba o aplicaciones con sistemas de correo alojado. Si ese fuera el caso, agregue, por favor, los dominios o direcciones IP de los servidores adicionales en los cuadros de texto que aparecen más abajo.`;
l.SPF.Servers.Others            = `Solo debe utilizar esta opción si existe algún sistema interno, que no sea un servidor de correo, que esté autorizado a enviar correos en nombre del dominio.`;
l.SPF.Servers.Added             = `Los sistemas que se añadan se incorporarán a las etiquetas de la política SPF.`;
l.SPF.Servers.InputField        = `Nombre del servidor o IP`;
l.SPF.Servers.InputInvalid      = `Debe ser una dirección IP o nombre de dominio FQDN (sigla en inglés de Fully Qualified Domain Name)`;
l.SPF.Servers.Next              = `Siguiente`;

l.SPF.Domains.Title             = `¿Existe algún dominio externo que pueda enviar o reenviar correo en nombre de su organización?`;
l.SPF.Domains.Include           = `Esta sección agregará la`;
l.SPF.Domains.Include2          = `etiqueta`;
l.SPF.Domains.Include3          = `incluir en la política SPF.`;
l.SPF.Domains.Trusted           = `Solo debe utilizar esta opción si existe algún dominio externo de confianza que pueda enviar correos en nombre del dominio de su organización. Por ejemplo, un Sistema de Gestión de Relaciones con el Cliente, un proveedor de correo la nube (Google, Office 365, Yahoo!) o un proveedor de seguridad en la nube.`;
l.SPF.Domains.Note              = `Por favor, tenga en cuenta que si utiliza un proveedor de servicios en la nube, debe consultar con ellos el valor apropiado para esta configuración.`;
l.SPF.Domains.InputField        = `Nombre de dominio o IP`;
l.SPF.Domains.InputInvalid      = `Debe ser una dirección IP o nombre de dominio FQDN (sigla en inglés de Fully Qualified Domain Name)`;
l.SPF.Domains.Next              = `Siguiente`;

l.SPF.Strict.Title              = `¿Cómo de estrictos deben ser los servidores tratando estos correos?`;
l.SPF.Strict.Receiving          = `Esta es la última sección de la política SPF que definirá cómo de estrictos deben ser los servidores destinatarios a la hora de tratar mensajes de correo electrónico.`;
l.SPF.Strict.SoftFail           = `Fallo laxo (recomendado)`;
l.SPF.Strict.Fail               = `Fallo estricto`;
l.SPF.Strict.Neutral            = `Neutral`;
l.SPF.Strict.AllowAll           = `Permitir todo `;
l.SPF.Strict.Next               = `Siguiente`;
l.SPF.Strict.Options            = `Opciones:`;
l.SPF.Strict.FailEx             = `Fallo estricto o `;
l.SPF.Strict.FailEx2            = `quiere decir que solo los servidores de correo del dominio (y aquellos que estén incluidos en las secciones adicionales) pueden enviar correos en nombre del dominio. Todos los demás están prohibidos.`;
l.SPF.Strict.SoftEx             = `Fallo laxo o `;
l.SPF.Strict.SoftEx2            = `quiere decir que solo los servidores de correo del dominio (y aquellos que estén incluidos en as secciones adicionales) pueden enviar correos en nombre del dominio, pero está en transición. Todos los demás están prohibidos.`;
l.SPF.Strict.NeutralEx          = `Neutral o `;
l.SPF.Strict.NeutralEx2         = `quiere decir que no se puede hacer ninguna afirmación sobre la validez.`;
l.SPF.Strict.AllowEx            = `Permitir todo o`;
l.SPF.Strict.AllowEx2           = `quiere decir que cualquier host puede enviar correos en nombre del dominio.`;
l.SPF.Strict.Warning1           = `Nunca `;
l.SPF.Strict.Warning2           = `debería`;
l.SPF.Strict.Warning3           = `usar ésta opción.`;
l.SPF.Strict.Recommended        = `Se recomienda utilizar la opción Fallo laxo`;
l.SPF.Strict.MarkMail           = `Si no cumple con los criterios establecidos, el correo se marcará como no conforme.`;

l.SPF.Results.Title             = `A continuación, se encuentra el registro SPF TXT que debe ser creado en su servidor DNS.`;
l.SPF.Results.Copy              = `Copiar`;
l.SPF.Results.Copied            = `¡COPIADO!`;
l.SPF.Results.DMARCGuide        = `Saltar hasta la Guía DMARC`;
l.SPF.Results.DKIMGuide         = `Continuar a la Guía DKIM`;
l.SPF.Results.OwnDNS            = `Si dispone de su propio servidor DNS, por favor, cree registros TXT para la política SPF anterior.`;
l.SPF.Results.ThirdParty        = `Si está usando un servidor DNS de terceros, por favor, siga sus instrucciones para crear un registro TXT.`;
l.SPF.Results.Reference         = `Por favor, consulte la`;
l.SPF.Results.ResourcesPage     = `página de recursos`;
l.SPF.Results.Information       = `para obtener información sobre cómo crear registros TXT.`;
l.SPF.Results.NotUnderstood     = `Si ha intentado seguir estas instrucciones pero no ha entendido alguno de los pasos o la terminología empleada, le recomendamos que se ponga en contacto con el equipo de Soporte Técnico de su organización, con el registrador de su dominio, o que lea la información de la GCA`;
l.SPF.Results.Assistance        = `para obtener más ayuda.`;
l.SPF.Results.Implemented       = `Una vez haya implementado el registro SPF, por favor, utilice la validación SPF que le facilitamos a continuación:`;
l.SPF.Results.YourDomain        = `sudominio.com`;
l.SPF.Results.Check             = `Comprobar`;
l.SPF.Results.SuccessDKIM       = `IR A DKIM`;
l.SPF.Results.SuccessDMARC      = `IR A DMARC`;
l.SPF.Results.NotQuite          = `No ha terminado todavía.`;
l.SPF.Results.Wisdom            = `¡Pero no se preocupe! La sabiduría es el objetivo final de cada acción. Por favor, no dude en usar nuestros recursos (mencionados arriba) o pedirnos ayuda con su implementación. Para eso estamos aquí.`;

l.DKIM.Home.Title               = `¿Qué es DKIM?`;
l.DKIM.Home.Mechanism           = `DKIM significa DomainKeys Identified Mail (mecanismo de autenticación de correo electrónico). DKIM es un mecanismo diseñado con el propósito de validar la identidad de dominios asociados a mensajes de correo electrónico. Esto se logra gracias al uso de criptografía asimétrica en el proceso de autenticación. En pocas palabras, DKIM autentica los mensajes de correo electrónico añadiendo una firma digital a la cabecera del mensaje. Es importante destacar que DKIM no filtra los mensajes de correo electrónico. Sino que permite a los filtros de SPAM determinar la autenticidad del mensaje de correo electrónico que se ha enviado.`;
l.DKIM.Home.ClickNext           = `Haga clic en el botón Siguiente para iniciar el proceso en el que le enseñaremos cómo crear la política DKIM.`;
l.DKIM.Home.YourDomain          = `sudominio.com`;
l.DKIM.Home.Next                = `Siguiente`;

l.DKIM.Selector.Title           = `Selector`;
l.DKIM.Selector.Name            = `El selector establecerá el nombre del registro DKIM. El nombre del selector puede ser cualquiera, pero a continuación encontrará algunas opciones predeterminadas.`;
l.DKIM.Selector.Define          = `Esto establecerá el nombre del registro TXT creado para la clave DKIM. Aparecerá de la siguiente forma:`;
l.DKIM.Selector.Example1        = `selector`;
l.DKIM.Selector.Example2        = `nombre del dominio`;
l.DKIM.Selector.Next            = `Siguiente`;

l.DKIM.Key.Title                = `Clave pública`;
l.DKIM.Key.Tag                  = `Esto añadirá la etiqueta p, que es la cadena de la clave pública generada anteriormente.`;
l.DKIM.Key.Keys                 = `Escriba aquí las claves públicas`;
l.DKIM.Key.Next                 = `Siguiente`;
l.DKIM.Key.Creating             = `Crear las claves DKIM:`;
l.DKIM.Key.TwoParts             = `DKIM tiene dos partes, la clave privada y clave pública. La creación de las claves DKIM depende del proveedor de servicios de correo electrónico de su organización.`;
l.DKIM.Key.External             = `Si está usando un servicio externo tanto de correo electrónico como de DNS, debe ser su proveedor de correo el que debe proporcionarle la clave pública. Esta clave pública es entonces publicada como un registro TXT en el servidor DNS. La mayoría de los proveedores DNS limitan el tamaño del registro TXT a 255 caracteres, por lo que puede que tenga que hablar con su proveedor para crear el registro TXT o para aumentar su tamaño. El proveedor de correo electrónico es el que tiene la clave privada y no la suele compartir con la organización.`;
l.DKIM.Key.Internal             = `Si la organización tiene su propio servidor de correo electrónico, las claves DKIM deberán ser generadas por el personal de TI (o por el responsable de la implementación DKIM). Existen varios generadores de claves DKIM disponibles en internet. Si elige usar alguno de estos generadores, asegúrese de que tiene la opción de eliminar la clave una vez haya sido generada y copiada a sus archivos.`;
l.DKIM.Key.OpenDKIM             = `Para crear las claves sin la ayuda de un tercero, puede usar un proyecto de código abierto`;
l.DKIM.Key.OpenDKIM2            = `llamado opendkim. Opendkim ofrece varias herramientas para ayudarle a crear la clave DKIM.`;
l.DKIM.Key.OpenSSL              = `Otra opción es usar OpenSSL para generar las claves DKIM. Puede hacerlo utilizando la versión más reciente de OpenSSL y escribiendo el siguiente comando para la clave privada:`;
l.DKIM.Key.OpenSSL2             = `Esto generará un archivo de clave privada llamado`;
l.DKIM.Key.OpenSSL3             = `A continuación, escriba el siguiente comando para generar la clave pública:`;
l.DKIM.Key.OpenSSL4             = `Este comando generará la clave pública`;
l.DKIM.Key.OpenSSL5             = `basándose en la clave privada generada anteriormente`;
l.DKIM.Key.OpenSSL6             = `Traslade la clave privada DKIM a la ubicación especificada por el instalador DKIM. Asegúrese de que está en una carpeta con acceso restringido.`;

l.DKIM.Results.Title            = `A continuación, se encuentra el registro SPF TXT que debe ser creado en su servidor DNS.`;
l.DKIM.Results.Copy             = `Copiar`;
l.DKIM.Results.Copied           = `¡COPIADO!`;
l.DKIM.Results.SPFGuide         = `Volver a la Guía de SPF`;
l.DKIM.Results.DMARCGuide       = `Continuar a la Guía de DMARC`;
l.DKIM.Results.OwnDNS           = `Si dispone de su propio servidor DNS, por favor, cree registros TXT para la política DKIM anterior.`;
l.DKIM.Results.ThirdParty       = `Si está usando un servidor DNS de terceros, por favor, siga sus instrucciones para crear un registro TXT.`;
l.DKIM.Results.Reference        = `Por favor, consulte la`;
l.DKIM.Results.ResourcesPage    = `página de recursos`;
l.DKIM.Results.Information      = `para obtener información sobre cómo crear registros TXT.`;
l.DKIM.Results.NotUnderstood    = `Si ha intentado seguir estas instrucciones pero no ha entendido alguno de los pasos o la terminología empleada, le recomendamos que se ponga en contacto con el equipo de Soporte Técnico de su organización, con el registrador de su dominio, o que lea la información de la GCA`;
l.DKIM.Results.Assistance       = `para obtener más ayuda.`;
l.DKIM.Results.Note             = `Por favor, tenga en cuenta lo siguiente:`;
l.DKIM.Results.Support          = `Las claves DKIM ahora pueden soportar claves de 2048 bits. Sin embargo, los registros TXT DNS no pueden soportar valores de más de 255 caracteres. Puede saltarse esta restricción con alguna de estas dos opciones:`;
l.DKIM.Results.Support2         = `Usar una clave de 1024 bits o`;
l.DKIM.Results.Support3         = `Crear un registro TXT de varias líneas`;
l.DKIM.Results.Support4         = `Encontrará ejemplos de registros de varias líneas a continuación (se suelen utilizar las comillas para romper una cadena en varias líneas):`;

l.DMARC.Home.Title              = `¿Qué es DMARC?`;
l.DMARC.Home.Final              = `Ahora que las políticas SPF y DKIM han sido creadas e implementadas. Podemos continuar con el último paso para crear la política DMARC. `;
l.DMARC.Home.Protocol           = `DMARC (siglas en inglés para autenticación, notificación y conformidad de mensajes basados en dominios) es un protocolo de autenticación de correo electrónico que incluye una función informativa que permite a los remitentes y destinatarios mejorar y monitorear la protección del dominio frente a correos electrónicos fraudulentos. La implementación de DMARC evitará que los spammers modifiquen fraudulentamente la dirección del remitente. Dependiendo de la configuración de política DMARC, los correos electrónicos procedentes de un servidor no autorizado serán puestos en cuarentena o rechazados directamente. Todos los mensajes de spam o phising que usen el nombre del dominio de la organización serán puestos en cuarentena o eliminados antes de llegar a su destino (usuario final). Los informes generados pueden ser utilizados con fines analíticos o incluso con fines legales, en caso de que la actividad tenga naturaleza criminal.`;
l.DMARC.Home.Stronger           = `DMARC fusiona las políticas SPF y DKIM para conseguir un sistema de autenticación de correo electrónico mucho más robusto para el dominio de su organización.`;
l.DMARC.Home.ClickNext          = `Por favor, haga clic en el botón Siguiente para iniciar el proceso en el que le enseñaremos cómo crear una política DMARC.`;
l.DMARC.Home.YourDomain         = `sudominio.com`;
l.DMARC.Home.Next               = `Siguiente`;

l.DMARC.Policy.Title            = `¿Cuál debería ser el nivel de la política DMARC?`;
l.DMARC.Policy.Levels           = `Se puede configurar la política DMARC bajo tres niveles de exigencia distintos. Son los siguientes:`;
l.DMARC.Policy.NoneEx           = `Ninguno -  Se informa sobre posibles mensajes fraudulentos, pero todos aparecen en la bandeja de entrada.`;
l.DMARC.Policy.QuarantineEx     = `Cuarentena - Si no se cumple la política SPF/DKIM, el mensaje se envía a la carpeta de correo no deseado.`;
l.DMARC.Policy.RejectEx         = `Rechazo - Si no se cumple la política SPF/DKIM, el mensaje se elimina y no llega al usuario final.`;
l.DMARC.Policy.None             = `Ninguno (recomendado)`;
l.DMARC.Policy.Quarantine       = `Cuarentena`;
l.DMARC.Policy.Reject           = `Rechazo`;
l.DMARC.Policy.Next             = `Siguiente`;
l.DMARC.Policy.Recommended1     = `Es`;
l.DMARC.Policy.Recommended2     = `muy recomendable`;
l.DMARC.Policy.Recommended3     = `empezar usando el nivel Ninguno, ya que así dispondrá de algo de margen para analizar los informes generados y determinar si se están bloqueando mensajes legítimos antes de activar un nivel de protección más estricto (cuarentena/rechazo).`;
l.DMARC.Policy.Tag              = `Esto añadirá`;
l.DMARC.Policy.Tag2             = `la etiqueta al registro DMARC.`;

l.DMARC.Aggregates.Title        = `¿Donde deberían enviarse los informes agregados?`;
l.DMARC.Aggregates.Opt          = `Es opcional, pero es muy recomendable y añadirá la`;
l.DMARC.Aggregates.Opt2         = `etiqueta rua a la política. Es`;
l.DMARC.Aggregates.Opt3         = `muy recomendable`;
l.DMARC.Aggregates.Opt4         = `que se añada a la política para fines analíticos y de monitoreo. Los informes se pueden enviar a varias direcciones.`;
l.DMARC.Aggregates.InputField   = `Dirección de correo electrónico`;
l.DMARC.Aggregates.InputInvalid = `Por favor, introduzca una dirección de correo electrónico válida`;
l.DMARC.Aggregates.Next         = `Siguiente`;
l.DMARC.Aggregates.Reports      = `Los informes agregados (formato XML) se generarán con la información relativa a los mensajes de correo que han pasado el filtro SPF y DKIM y los que no. Esto proporciona visibilidad sobre posibles cuestiones de autenticación y/o correos spam dirigidos a su organización.`;
l.DMARC.Aggregates.Contains     = `Los informes contienen lo siguiente`;
l.DMARC.Aggregates.HowToRead    = `Cómo leer informes DMARC por primera vez`;
l.DMARC.Aggregates.By           = `Parte 1 (por Amy Gorrell):`;
l.DMARC.Aggregates.Info         = `Información ISP`;
l.DMARC.Aggregates.Desc         = `Descripción línea a línea del registro DMARC de su organización`;
l.DMARC.Aggregates.Summary      = `Resumen de los resultados de autenticación`;
l.DMARC.Aggregates.Important    = `Es importante destacar que estos informes contienen información de más de una fuente. Podría recibir entre 10 y 100 (o incluso más de mil) informes diarios (el número de informes generados depende de cantidad de mensajes enviados).`;
l.DMARC.Aggregates.Difficult    = `Esto dificultará la tarea de revisar manualmente cada informe, sobretodo teniendo en cuenta que están en formato XML y que algunos pueden ser muy largos.`;
l.DMARC.Aggregates.Readable     = `Estos informes pueden ser traducirse a un formato más amigable desarrollando un conversor XML o contando con la ayuda de algún otro software/servicio comercial. La mayoría de los software/servicios comerciales proporcionan un repositorio que traduce los informes para que puedan ser leídos con facilidad, ofreciendo varios niveles de servicios de análisis y orientación.`;
l.DMARC.Aggregates.Vendors      = `Proveedores DMARC (socios de la GCA):`;

l.DMARC.Forensics.Title         = `¿Dónde deberían enviarse los informes forenses?`;
l.DMARC.Forensics.Opt           = `Esto es opcional y añadirá la`;
l.DMARC.Forensics.Opt2          = `etiqueta ruf a la política. Estos informes XML solo se generarán si el destinatario genera este tipo de informes. Existe la posibilidad que no reciba ningún informe forense. Esto es debido a las normativas de privacidad y de uso compartido de datos entre países.`;
l.DMARC.Forensics.InputField    = `Dirección de correo electrónico`;
l.DMARC.Forensics.InputInvalid  = `Por favor, introduzca una dirección de correo electrónico válida`;
l.DMARC.Forensics.Next          = `Siguiente`;

l.DMARC.Subdomains.Title        = `¿Política DMARC para subdominios?`;
l.DMARC.Subdomains.Define       = `Esto definirá el nivel de la política DMARC para los subdominios de su organización. Si esta etiqueta`;
l.DMARC.Subdomains.Define2      = `no está presente, la política del subdominio se heredará de la política del nivel superior, la política del dominio que fue definida en el primer paso.`;
l.DMARC.Subdomains.None         = `Ninguno`;
l.DMARC.Subdomains.Quarantine   = `Cuarentena`;
l.DMARC.Subdomains.Reject       = `Rechazo`;
l.DMARC.Subdomains.Next         = `Siguiente`;
l.DMARC.Subdomains.Policy       = `Política del dominio. Las opciones son:`;
l.DMARC.Subdomains.NoneEx       = `Ninguno - No se lleva a cabo ninguna acción, pero se registra en el informe DMARC.`;
l.DMARC.Subdomains.QuarantineEx = `Cuarentena - El mensaje de correo se marca como spam.`;
l.DMARC.Subdomains.RejectEx     = `Rechazo - Se elimina el mensaje.`;
l.DMARC.Subdomains.Recommended  = `Si cuenta con varios subdominios, le recomendamos elegir Ninguno. Es mejor establecer diferentes políticas DMARC para cada subdominio hasta que todos los subdominios sean compatibles con la política DMARC. Sin embargo, si no hay definido ningún subdominio, se recomienda usar la opción Rechazo.`;

l.DMARC.Options.Title           = `(Opcional) Las opciones restantes no son necesarias pero pueden ajustarse si es necesario:`;
l.DMARC.Options.ReportGen       = `1. Cuándo debe generarse el informe de errores`;
l.DMARC.Options.Default         = `Predeterminado`;
l.DMARC.Options.ReportPol       = `Define la política de notificación de errores.`;
l.DMARC.Options.DefaultsTo      = `Si no se define, se marca por defecto la opción`;
l.DMARC.Options.AllChecks       = `Generar informe al remitente MTA si las verificaciones subyacentes fallan`;
l.DMARC.Options.AnyChecks       = `Generar informe al remitente MTA si las verificaciones subyacentes fallan`;
l.DMARC.Options.OtherValues     = `Otros valores válidos son:`;
l.DMARC.Options.DKIMFails       = `Generar un informe si la verificación DKIM falla`;
l.DMARC.Options.SPFFails        = `Generar un informe si la verificación SPF falla`;
l.DMARC.Options.Relaxed         = `Laxo`;
l.DMARC.Options.Strict          = `Estricto`;
l.DMARC.Options.DKIMAlign       = `2. Modo de alineación DKIM`;
l.DMARC.Options.DKIMAlignOpt    = `Modo de alineación DKIM. Las opciones son:`;
l.DMARC.Options.DKIMRelaxed     = `(laxo) - esta es la opción por defecto si no se define manualmente. Permite cualquier subdominio definido en la cabecera DKIM.`;
l.DMARC.Options.DKIMStrict      = `(estricto) - el dominio del remitente debe coincidir de forma exacta con el dominio de la cabecera DKIM.`;
l.DMARC.Options.SPFAlign        = `3. Modo de alineación SPF`;
l.DMARC.Options.SPFAlignOpt     = `Modo de alineación SPF. Las opciones son:`;
l.DMARC.Options.SPFRelaxed      = `(laxo) - opción por defecto si no se define manualmente.  Permite cualquier subdominio.`;
l.DMARC.Options.SPFStrict       = `(estricto) - el nombre del dominio de la organización en el campo CORREO DE: (del protocolo SMTP) y el de la cabecera DE: (del propio correo) deben coincidir de forma exacta`;
l.DMARC.Options.Percentage      = `4. Porcentaje de mensajes controlados por la política DMARC`;
l.DMARC.Options.Filter          = `% de mensajes controlados por la política DMARC.  Puede ser cualquier número del 1 al 100. Por defecto es 100, que implica todos los mensajes.`;
l.DMARC.Options.Formats         = `5. Formato de los informes generados`;
l.DMARC.Options.FormatsEx       = `Define el formato de los informes generados.  Los valores de formato son:`;
l.DMARC.Options.RFC             = `valor por defecto si no se define.  El formato está definido por la RFC 5965.`;
l.DMARC.Options.RFC2            = `El formato está definido por la RFC 5070.`;
l.DMARC.Options.ReportInt       = `6. Intervalo de generación de informes (segundos)`;
l.DMARC.Options.SecondsDefault  = `segundos (por defecto)`;
l.DMARC.Options.DigitsOnly      = `Por favor, introduzca solo dígitos`;
l.DMARC.Options.ReportIntEx     = `Define los intervalos de generación de informes en segundos.  Si no se define, el valor por defecto es 86 400 segundos (24 horas). Por favor, tenga en cuenta que no se garantiza el envío de informes por parte del MTA destinatario.  Los informes se envían en medida de lo posible.`;
l.DMARC.Options.HToS            = `Conversor de horas a segundos.`;
l.DMARC.Options.Hours           = `24 horas`;
l.DMARC.Options.To              = `a`;
l.DMARC.Options.Seconds         = `86400 segundos`;
l.DMARC.Options.Finish          = `Terminar`;

l.DMARC.Results.Title           = `A continuación, le facilitamos el registro DMARC TXT que debe crear en su servidor DNS.`;
l.DMARC.Results.Display         = `Mostrar resultado del registro DMARC TXT`;
l.DMARC.Results.Copy            = `Copiar`;
l.DMARC.Results.Copied          = `¡COPIADO!`;
l.DMARC.Results.SPFGuide        = `Volver a la Guía SPF`;
l.DMARC.Results.DKIMGuide       = `Volver a la Guía DKIM`;
l.DMARC.Results.OwnDNS          = `Si tiene su propio servidor DNS, le rogamos que cree registros TXT para la política DMARC siguiente.`;
l.DMARC.Results.ThirdParty      = `Si está usando un servidor DNS de terceros, por favor, siga sus instrucciones para crear un registro TXT. En la mayoría de los casos, solo necesitará el nombre del host, _dmarc.<nombreDelDominio> y el valor del texto, que es lo que aparece entre comillas.`;
l.DMARC.Results.Reference       = `Por favor, consulte la`;
l.DMARC.Results.ResourcesPage   = `página de recursos`;
l.DMARC.Results.Information     = `para obtener más información sobre cómo crear registros TXT.`;
l.DMARC.Results.NotUnderstood   = `Si ha intentado seguir estas instrucciones pero no ha entendido alguno de los pasos o la terminología empleada, le recomendamos que se ponga en contacto con el equipo de Soporte Técnico de su organización, con su registrador de dominio o que lea la información que facilita la GCA`;
l.DMARC.Results.Assistance      = `para obtener ayuda adicional.`;
l.DMARC.Results.Complete        = `Completar`;
l.DMARC.Results.Implemented     = `Una vez se haya implementado el registro DMARC, por favor, utilice la validación DMARC que le mostramos a continuación:`;
l.DMARC.Results.YourDomain      = `sudominio.com`;
l.DMARC.Results.NotQuite        = `No ha terminado todavía.`;
l.DMARC.Results.Wisdom          = `¡Pero no se preocupe! La sabiduría es el objetivo final de cada acción. Por favor, no dude en usar nuestros recursos (mencionados arriba) o pedirnos ayuda con su implementación. Para eso estamos aquí.`;
l.DMARC.Results.Check           = `Comprobar`;
l.DMARC.Results.Congratulations = `¡Felicidades!`;

export default l;
